import DOMHelp from "./utils/html";
import Polyfils from "./utils/polyfils";
import PlayerHandler from "./PlayerHandler/PlayerHandler";
import PlayerHandlerLocal, { ErrorObject } from "./PlayerHandler/PlayerHandlerLocal";
import PLAYER_STATE from "./PlayerHandler/PlayerState";
import { mergeDeep, cloneDeep } from "./utils/deepMerge";
import { IconPlay, IconPause } from "./utils/icons";
import Settings from "./PlayerSettings";
import PlaybackRate from "./PlaybackRate";
import QualitySelector from "./QualitySelector";
import Fullscreen from "./Fullscreen";
import Volume from "./Volume";
import ProgressBar from "./ProgressBar";
import Chromecast from "./Chromecast";
import Ads from "./Ads";
import PlayQualityOverlay from "./PlayQualityOverlay";
import VideoEndOverlay from "./VideoEndOverlay";
import Keybinds from "./PlayerKeyHandler";
import Widescreen from "./Widescreen";
import ErrorStorage from "./utils/errorStorage";
import StorageProvider from "./utils/Storage";
import VideoGesturesOverlay from "./VideoGesturesOverlay";
import HttpModule from "./utils/http";
import Lang from "./utils/Lang";
import ContextMenu from "./ContextMenu";
import { defaultConfig, PlayerConfig } from "./_config";
import packageJson from "../../package.json";
import PlayerStateProvider from "./PlayerHandler/PlayerStateProvider";
import VASTHandler from "./VASTHandler";
import Api from "./Api";
import Hls from "../../node_modules/hls.js";
import { CopyToClipboard } from "./utils/copyClipboard";
import doc = Mocha.reporters.doc;
import VideoStats from "./VideoStats";
import TextAd from "./TextAd";
import VideoAd from "./VideoAd";

export class VideoPlayer {
  private errorBox: any;
  private optionsDefault: PlayerConfig;
  private options: PlayerConfig;
  private firstStart: boolean;
  private controlsContainerTimer: number;
  // private debounce: number;
  private lang: any;
  private http: HttpModule;
  private playerWrapperWidthBuffer: number = 0;

  public isReduced: boolean = false;
  public reducedVideoStartTime: number = 0;
  public reducedVideoDuration: number;
  public startTimeFromSearchParams: number;

  private brandPrerollCounter: number = 0;
  private brandPrerollCounterPrefix: string = "_brandPrerollCounter";
  public wrapper: HTMLElement;
  public storage: StorageProvider;
  public touchModeEnabled: boolean;
  public video: any;
  public version: string = "0.0.0";
  public playerHandlerLocal: PlayerHandlerLocal;
  public playerHandler: PlayerHandler;
  public playerState: PlayerStateProvider;
  public vastHandler: VASTHandler;
  public api: Api;
  public hls: Hls;
  public isHlsEnabled: boolean;
  public isBrandPrerollShown: boolean = false;
  public isSourceVideoShown: boolean = false;
  public settings: any;
  public videoEndedOverlay: any;
  public ads: any;
  public playButtonOverlay: any;
  public container: any;
  public containerWrapper: any;
  public playbackRate: any;
  public qualitySelector: any;
  public poster: any;
  public titleWrapper: any;
  public titleText: any;
  public copyLink: any;
  public copyLinkWrapper: any;
  public showDetailsButton: any;
  public showDetailsButtonWrapper: any;
  public bufferingSpinnerWrapper: any;
  public bufferingSpinner: any;
  public controlsContainer: any;
  public progressBar: any;
  public controlsBottomContainer: any;
  public controlsBottomLeft: any;
  public controlsBottomRight: any;
  public watermark: any;
  public volume: Volume;
  public chromecast: any;
  public keyBindHandler: any;
  public videoGesturesOverlay: any;
  public clickContextMenu: any;
  public playPauseBtnWrapper: any;
  public playPauseBtn: any;
  public fullscreen: any;
  public widescreen: any;
  public aspectRatioFix: any;
  public isVASTComplete: boolean;
  public isAdsEnabled: boolean;
  public isMobile: boolean;
  public isEndCallbackFired: boolean;
  public videoDetailsHTMLElement: any;
  public videoDetailsIsShown: boolean = false;
  public isVideoDetailsShown: boolean = false;
  private videoStats: any;
  public textAd: any;
  public videoAd: any;

  constructor(target: string, options: PlayerConfig) {
    this.isHlsEnabled = false;
    this.isVASTComplete = false;
    this.isAdsEnabled = true;
    this.version = packageJson.version;
    new Polyfils();
    this.optionsDefault = cloneDeep(defaultConfig);
    this.options = mergeDeep(cloneDeep(defaultConfig), options);
    this.wrapper = document.getElementById(target);
    this.firstStart = true;
    this.storage = new StorageProvider();
    this.touchModeEnabled = matchMedia("(pointer:coarse)").matches || false;
    this.lang = new Lang(this.options.language);
    this.http = new HttpModule();
    this.playerState = new PlayerStateProvider();
    this.api = new Api(this);
    this.isVideoDetailsShown = this.storage.load("_isVideoDetailsShown", false);
    this.isMobile = !!(
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
    this.isEndCallbackFired = false;
  }

  init(): any {
    // ~~~> Update player options
    this.updatePlayerOptions();

    // Render video details buttin for mobile
    this.renderVideoDetailsButton();
    this.renderPlayerBase();

    // Setup player handlers
    this.playerHandlerLocal = new PlayerHandlerLocal(this.video, this.playerState, this);
    this.playerHandler = this.playerHandlerLocal;

    // Render player UI
    this.renderPlayerUI();

    // Render buffering process
    this.uiToggleBuffering(true);

    // Check activeSource
    if (!this.qualitySelector.activeSource.src || !this.qualitySelector.activeSource.desc) {
      this.uiRenderError(this.lang.get("VIDEO_CONFIG"));
      return;
    }

    // Check brand video availability
    this.checkBrandPreroll();

    // Init video stats
    this.videoStats = new VideoStats(this);

    // Check HLS support
    if (this.qualitySelector.activeSource["hls"]) {
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(this.qualitySelector.activeSource.src);
        this.hls.attachMedia(this.video);
        this.isHlsEnabled = true;

        this.hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.response && data.response.code === 404) {
            this.handleHLSNetworkError();
          }
        });

        this.initVAST();
      } else if (this.video.canPlayType("application/vnd.apple.mpegurl")) {
        this.video.src = this.qualitySelector.activeSource.src;
        this.initVAST();
      } else {
        this.uiRenderError("Your browser does not support HLS videos!");
      }

      return this.api;
    }

    // Check video support
    const isVideoSupportEnabled = !!document.createElement("video").canPlayType("video/mp4");

    if (isVideoSupportEnabled) {
      this.initVAST();
    } else {
      this.uiRenderError("Your browser does not support MP4 videos!");
    }

    return this.api;
  }

  private renderVideoDetailsButton(): void {
    // Show video details
    document.addEventListener("DOMContentLoaded", () => {
      if (this.isMobile && this.options.detailsButton.enabled) {
        this.videoDetailsHTMLElement = document.querySelector(this.options.detailsButton.selector);

        if (this.videoDetailsHTMLElement) {
          this.showDetailsButton = new DOMHelp("p")
            .addClass("show-details-inner")
            .addClass("copy-link-inactive")
            .text(this.lang.get("showVideoDetails"));
          this.showDetailsButtonWrapper = new DOMHelp("div")
            .addClass("show-details-wrapper")
            .add(this.showDetailsButton);
          this.showDetailsButtonWrapper.hide();
          this.container.add(this.showDetailsButtonWrapper);

          this.showDetailsButtonWrapper.on("click", () => {
            this.isVideoDetailsShown = !this.isVideoDetailsShown;
            this.storage.save("_isVideoDetailsShown", this.isVideoDetailsShown);

            if (this.isVideoDetailsShown) {
              this.videoDetailsHTMLElement.style.display = "block";
              this.showDetailsButton.text(this.lang.get("hideVideoDetails"));
            } else {
              this.videoDetailsHTMLElement.style.display = "none";
              this.showDetailsButton.text(this.lang.get("showVideoDetails"));
            }
          });

          this.playerState.state.subscribe(state => {
            if (state === PLAYER_STATE.PLAYING) {
              this.video.style.removeProperty("display");
            }

            if (state === PLAYER_STATE.PAUSED && !this.videoDetailsIsShown) {
              this.showDetailsButtonWrapper.show();
            }

            if (
              state === PLAYER_STATE.PLAYING ||
              this.video.currentTime === 0 ||
              this.vastHandler.isActive ||
              this.video.src === this.options.brandPreroll.url
            ) {
              this.showDetailsButtonWrapper.hide();
            }
          });
        }
      }

      this.showVideoDetails();
    });
  }

  private showVideoDetails(): void {
    if (this.isMobile && this.isVideoDetailsShown) {
      this.videoDetailsHTMLElement = document.querySelector(this.options.detailsButton.selector);

      if (this.videoDetailsHTMLElement) {
        this.videoDetailsHTMLElement.style.display = "block";
      }
    }
  }

  private updatePlayerOptions(): void {
    // Update reduced video options
    this.reducedVideoStartTime = this.options.reduceVideo.startTime;
    this.reducedVideoDuration = this.options.reduceVideo.duration;

    if (!this.options.interstitialAd.enabled && this.options.videoEndOverlay.enabled) {
      this.options.interstitialAd.enabled = true;
    }

    // Disable VAST for unstable IE11 case
    const isIE11: boolean = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
    const isSmartTV: boolean = /SMART-TV/.test(navigator.userAgent);

    if (isIE11 || isSmartTV) {
      this.options.prerollAd.enabled = false;
    }
  }

  private getCookie(name: string): string | null {
    const value: string = "; " + document.cookie;
    const parts: string[] = value.split("; " + name + "=");

    if (parts.length === 2) {
      return parts
        .pop()
        .split(";")
        .shift();
    }

    return null;
  }

  private getBrandPrerollCounter(): number {
    return +this.getCookie(this.brandPrerollCounterPrefix) || 0;
  }

  private setBrandPrerollCounter(value: any): void {
    this.setCookie(this.brandPrerollCounterPrefix, value, 0);
  }

  private setCookie(name: string, value: any, hoursToExpire: number): void {
    let expires: string = "";

    if (hoursToExpire) {
      const expirationDate: number = +new Date() + hoursToExpire * 60 * 60 * 1000;
      expires = "; expires=" + new Date(expirationDate).toUTCString();
    }

    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
  }

  private checkBrandPreroll() {
    if (!this.options.brandPreroll.url) {
      this.options.brandPreroll.enabled = false;
    }
  }

  private initVAST(): void {
    this.vastHandler = new VASTHandler(this);
    this.vastHandler.isLoaded.subscribe(value => {
      if (value) {
        this.playerState.state.subscribe(state => {
          state === PLAYER_STATE.ERROR && this.handleError();
          state === PLAYER_STATE.LOADED && this.initVideo();
          state === PLAYER_STATE.ENDED && this.continueAfterBrandPreroll();
        });
      }
    });
  }

  public continueAfterVAST(setPause: boolean): void {
    this.vastHandler.isShown = true;

    // BRAND PREROLL VIEW LIMIT CHECK
    if (this.options.brandPreroll.enabled && this.options.brandPreroll.viewLimit > 0) {
      this.brandPrerollCounter = this.getBrandPrerollCounter();

      if (this.vastHandler.isVastSkipped) {
        this.brandPrerollCounter++;
      }

      if (this.brandPrerollCounter > this.options.brandPreroll.viewLimit) {
        this.options.brandPreroll.enabled = false;
      }

      this.setBrandPrerollCounter(this.brandPrerollCounter);
    }

    if (this.isBrandPrerollisAllowed()) {
      // Show brand preroll
      this.video.src = this.options.brandPreroll.url;

      if (!setPause) {
        this.playerHandler.play();
      }

      this.showControlsBar(4000);
      this.isBrandPrerollShown = true;
      return;
    } else {
      this.isSourceVideoShown = true;
    }

    if (this.hls) {
      this.hls.detachMedia();
    }

    if (this.qualitySelector.activeSource["hls"]) {
      this.isBrandPrerollShown = true;
      this.hls = new Hls();
      this.hls.loadSource(this.qualitySelector.activeSource.src);
      this.hls.attachMedia(this.video);
      this.vastHandler.isShown = true;
      this.showControlsBar(4000);

      // Check if autoplay is enabled
      if (this.isAutoplay()) {
        this.playerHandler.play();
      }

      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.response && data.response.code === 404) {
          this.handleHLSNetworkError();
        }
      });
    } else {
      this.isBrandPrerollShown = true;

      this.video.src = this.qualitySelector.activeSource.src;

      // Check if autoplay is enabled
      if (this.isAutoplay()) {
        this.playerHandler.play();
      }
      // this.initVAST();
    }

    // Init text ad
    this.textAd = this.textAd ? this.textAd : new TextAd(this);
    this.videoAd = this.videoAd ? this.videoAd : new VideoAd(this);
  }

  public isBrandPrerollisAllowed(): boolean {
    return (
      this.options.brandPreroll.enabled &&
      this.options.brandPreroll.url &&
      this.vastHandler.isVastSkipped &&
      !this.vastHandler.isSkipButtonClicked
    );
  }

  public continueAfterBrandPreroll() {
    // Update isSourceVideoShown
    if (!this.options.brandPreroll.enabled && (!this.options.prerollAd.enabled || this.vastHandler.isVastSkipped)) {
      this.isSourceVideoShown = true;
    }

    if (this.isSourceVideoShown) {
      // onEnd callback call
      if (!this.isEndCallbackFired && typeof this.options.onEndCallback === "function") {
        this.options.onEndCallback();
        this.isEndCallbackFired = true;
      }

      return;
    }

    this.isSourceVideoShown = true;

    if (this.isBrandPrerollShown && this.options.brandPreroll.enabled && this.options.brandPreroll.url) {

      // Init text ad
      this.textAd = this.textAd ? this.textAd : new TextAd(this);
      this.videoAd = this.videoAd ? this.videoAd : new VideoAd(this);

      this.options.brandPreroll.enabled = false;

      if (this.hls) {
        this.hls.detachMedia();
      }

      if (this.playButtonOverlay) {
        this.playButtonOverlay.hide();
      }

      if (this.qualitySelector.activeSource["hls"]) {
        this.hls = new Hls();
        this.hls.loadSource(this.qualitySelector.activeSource.src);
        this.hls.attachMedia(this.video);
        this.playerHandler.play();
        this.showControlsBar(4000);

        this.hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.response && data.response.code === 404) {
            this.handleHLSNetworkError();
          }
        });
      } else {
        this.video.src = this.qualitySelector.activeSource.src;
        this.playerHandler.play();
      }
    }
  }

  private initVideo(): void {
    this.setPlayerSize();

    if (!this.video.currentTime) {
      if (this.vastHandler.isReadyToInit || this.isBrandPrerollisAllowed()) {
        this.video.currentTime = 0;
      } else {
        this.video.currentTime = this.options.startTime || this.getStartTime() || 0;
      }
    }

    if (!this.vastHandler.isReadyToInit && !this.isBrandPrerollisAllowed()) {
      // Init text & video ads

      this.textAd = this.textAd ? this.textAd : new TextAd(this);
      this.videoAd = this.videoAd ? this.videoAd : new VideoAd(this);
    }

    // renderIfAutoplayIsDisabled()
    if (this.isAutoplay()) {
      // Previous condition: this.isAutoplay() || this.video.currentTime
      // include autoplay bug fix
      try {
        this.playerHandlerLocal
          .play() // Promise added to catch an error
          .catch(error => {
            if (error.name === "NotAllowedError") {
              this.renderIfAutoplayIsDisabled();
            }
          });
      } catch (error) {
        this.log(error);
        this.renderIfAutoplayIsDisabled();
      }
    } else {
      this.renderIfAutoplayIsDisabled();
    }
  }

  renderIfAutoplayIsDisabled(): void {
    // && !(this.isReduced && this.video.currentTime === this.reducedVideoStartTime)
    if (
      this.video.ended &&
      this.options.videoEndOverlay.enabled &&
      !(this.isReduced && this.video.currentTime === this.reducedVideoStartTime)
    ) {
      this.videoEndedOverlay.show();
    } else {
      this.ads.show();
      this.playButtonOverlay.show();
    }
  }

  renderPlayerBase(): void {
    this.container = new DOMHelp().create("figure");
    this.containerWrapper = new DOMHelp()
      .create("div")
      .addClass("video-player")
      .add(this.container);

    this.playbackRate = new PlaybackRate(this);

    const videoEl = new DOMHelp()
      .create("video")
      .hide()
      .attr("preload", "metadata");
    this.container.add(videoEl);
    videoEl.attr("playsinline", null);
    this.video = videoEl.get();

    this.qualitySelector = new QualitySelector(this);
    this.video.src = this.qualitySelector.getSource();

    this.poster = new DOMHelp()
      .create("div")
      .addClass("poster")
      .style("backgroundImage", `url("${this.options.poster}")`);

    this.container.add(this.poster);

    if (this.touchModeEnabled) {
      this.container.addClass("touch-mode");
    }

    this.poster.on("click", () => this.handlePlayPause());

    this.container.on("mousemove", () => {
      if (!this.touchModeEnabled) {
        this.handleMouseOver();
        this.controlsContainerTimer = window.setTimeout(() => {
          this.handleMouseOut();
        }, 2000);
      }
    });
    this.handleMouseOver();

    this.container.on("mousedown", () => this.container.addClass("mouse-down"));
    document.addEventListener("mouseup", () => this.container.removeClass("mouse-down"));

    this.setPlayerSize = this.throttle(this.setPlayerSize, 100);

    window.addEventListener("resize", () => {
      this.setPlayerSize();
    });

    this.playerState.isWidescreen.subscribe(() => setTimeout(() => this.setPlayerSize(), 25));
    this.playerState.isFullscreen.subscribe(() => setTimeout(() => this.setPlayerSize(), 25));
  }

  throttle(f, ms): any {
    let isThrottled = false,
      savedArgs,
      savedThis;

    function wrapper() {
      if (isThrottled) {
        savedArgs = arguments;
        savedThis = this;

        return;
      }

      f.apply(this, arguments);

      isThrottled = true;

      setTimeout(function() {
        isThrottled = false;

        if (savedArgs) {
          wrapper.apply(savedThis, savedArgs);
          savedArgs = savedThis = null;
        }
      }, ms);
    }

    return wrapper;
  }

  renderPlayerUI(): void {
    this.titleText = new DOMHelp("p").addClass("title-inner").text(this.options.title);
    this.titleWrapper = new DOMHelp("div").addClass("video-title-wrapper").add(this.titleText);

    if (this.options.title) {
      this.container.add(this.titleWrapper);
    }

    // Copy link wrapper
    if (this.options.showCopyLink) {
      this.copyLink = new DOMHelp("p")
        .addClass("copy-link-inner")
        .addClass("copy-link-inactive")
        .text(this.lang.get("copyLink"));
      this.copyLinkWrapper = new DOMHelp("div").addClass("copy-link-wrapper").add(this.copyLink);
      this.container.add(this.copyLinkWrapper);

      this.copyLinkWrapper.on("click", () => {
        CopyToClipboard(this.options.copyUrl);
        this.copyLink.text(this.lang.get("copyLinkDone"));
        this.copyLink.removeClass("copy-link-inactive");
        this.copyLink.addClass("copy-link-active");

        setTimeout(() => {
          this.copyLink.text(this.lang.get("copyLink"));
          this.copyLink.removeClass("copy-link-active");
          this.copyLink.addClass("copy-link-inactive");
        }, 1000);
      });
    }

    this.uiRenderBuffering();

    this.controlsContainer = new DOMHelp().create("div").addClass("control-bar");
    this.container.add(this.controlsContainer);

    this.progressBar = new ProgressBar(this);
    this.controlsContainer.add(this.progressBar.getProgressBar());

    if (this.isMobile) {
      this.controlsContainer.hide();

      this.playerState.state.subscribe(state => {
        if (state === PLAYER_STATE.PLAYING) {
          this.video.style.removeProperty("display");
          this.controlsContainer.show();
        }
      });
    }

    this.controlsBottomContainer = new DOMHelp().create("div").addClass("control-bar-bottom");
    this.controlsBottomLeft = new DOMHelp()
      .create("div")
      .addClass("control-bar-left-wrapper")
      .style("opacity", "0");
    this.controlsBottomRight = new DOMHelp().create("div").addClass("control-bar-right-wrapper");
    this.controlsBottomContainer.add(this.controlsBottomLeft).add(this.controlsBottomRight);
    this.renderPlayPause();

    this.controlsBottomLeft.add(this.progressBar.getTimer());

    this.volume = new Volume(this);
    this.controlsBottomLeft.add(this.volume.getVolumeBar());

    this.chromecast = new Chromecast(this);

    this.settings = new Settings(this);
    this.controlsBottomRight.add(this.settings.getSettingsWrapper());
    this.renderWidescreen();
    this.renderFullscreen();
    this.controlsContainer.add(this.controlsBottomContainer);

    new DOMHelp(this.wrapper).clearChilds().add(this.containerWrapper);
    this.setPlayerSize();

    this.keyBindHandler = new Keybinds(this);

    if (this.options.logo) {
      this.container.add(this.renderWatermark());
    }

    this.ads = new Ads(this);
    this.playButtonOverlay = new PlayQualityOverlay(this);

    if (this.options.videoEndOverlay.enabled) {
      this.videoEndedOverlay = new VideoEndOverlay(this);
    }

    this.videoGesturesOverlay = new VideoGesturesOverlay(this);
    this.clickContextMenu = new ContextMenu(this);
  }

  renderPlayPause(): void {
    this.playPauseBtnWrapper = new DOMHelp()
      .create("div")
      .addClass("play-pause-button-wrapper")
      .addClass("tooltip-button")
      .addClass("tooltip-far-left");
    this.playPauseBtn = new DOMHelp().create("button").addClass("play-pause-button");
    this.uiRenderPause();
    this.controlsBottomLeft.add(this.playPauseBtnWrapper.add(this.playPauseBtn));
    this.playPauseBtnWrapper.on("click", () => /*this.handlePlayPause()*/ this.playerHandler.handlePlayPause());

    this.playerState.state.subscribe(state => {
      if (state === PLAYER_STATE.PLAYING) {
        this.uiRenderPlay();

        this.video.style.removeProperty("display");

        if (this.playButtonOverlay) {
          this.playButtonOverlay.hide();
        }
      } else {
        this.uiRenderPause();
      }
    });
  }

  renderFullscreen(): void {
    this.fullscreen = new Fullscreen(this);
    this.controlsBottomRight.add(this.fullscreen.getBtn());
  }

  renderWidescreen(): void {
    if (this.options.widescreen.enabled) {
      this.widescreen = new Widescreen(this);
      this.controlsBottomRight.add(this.widescreen.getBtn());
    }
  }

  renderWatermark(): any {
    this.watermark = new DOMHelp()
      .create("a")
      .addClass("watermark")
      .add(new DOMHelp().create("img").attr("src", this.options.logo));
    if (this.options.logoLink) {
      this.watermark.attr("href", this.options.logoLink).attr("target", "_blank");
    }
    return this.watermark;
  }

  handlePlayPauseOnKey(): void {
    if (this.video.paused) {
      this.play();

      // ~~~> CONTEXT MENU UPDATE <~~~ //
      if (this.options.videoEndOverlay.enabled && this.videoEndedOverlay) {
        this.videoEndedOverlay.isRendered = false;
      }
    } else {
      this.pause();
    }
  }

  handlePlayPause(): void {
    if (this.video.paused) {
      this.play();

      // ~~~> CONTEXT MENU UPDATE <~~~ //
      if (this.options.videoEndOverlay.enabled && this.videoEndedOverlay) {
        this.videoEndedOverlay.isRendered = false;
      }
    } else {
      this.pause();
    }
  }

  setPlayerSize(): void {
    // Get video size
    let videoWidth: number = this.video.videoWidth || this.options.width || 240;
    let videoHeight: number = this.video.videoHeight || this.options.height || 240;

    // Get aspect ratio
    const aspectRatio: number = Math.max(videoWidth, videoHeight) / Math.min(videoWidth, videoHeight);

    // Get player wrapper element
    const playerWrapper: HTMLDivElement = this.containerWrapper.get();

    // Get player wrapper size
    const playerWrapperWidth: number = playerWrapper.offsetWidth;
    const playerWrapperHeight: number = Math.round((playerWrapperWidth * 9) / 16);

    // Get video element
    const videoElement = new DOMHelp(this.video);

    // Update video size
    videoWidth = Math.floor(playerWrapperHeight * aspectRatio);
    videoHeight = playerWrapperHeight;

    // Update time bar on resize event
    this.progressBar.updateTimeBarOnResize();

    // Set video size
    if (this.playerState.isFullscreen.getValue()) {
      videoElement.style("width", "100%");
      videoElement.style("height", "100%");
    } else {
      videoElement.style("width", `${videoWidth}px`);
      videoElement.style("height", `${videoHeight}px`);
    }

    // Aspect ratio fix
    if (!this.aspectRatioFix) {
      this.aspectRatioFix = new DOMHelp().create("div").addClass("aspect-ratio-fix");
      this.containerWrapper.add(this.aspectRatioFix);
    }

    this.aspectRatioFix.style("height", `${videoHeight}px`);
  }

  play(): void {
    this.playerHandler.play();
  }

  pause(): void {
    if (this.videoEndedOverlay) {
      this.videoEndedOverlay.isOverlayWasHidden = false;
    }

    if (!this.options.interstitialAd.enabled) {
      this.options.videoEndOverlay.enabled = true;
    }

    this.playerHandler.pause();
  }

  handleVideoClick(event): void {
    if (
      this.settings.settingsWrapper.isVisible() ||
      this.settings.settingsPlayRateWrapper.isVisible() ||
      this.settings.settingsQualityWrapper.isVisible()
    ) {
      return;
    }
    const el = event.target;

    if (el.getAttribute("data-dblclick") == null) {
      el.setAttribute("data-dblclick", 1);
      setTimeout(() => {
        if (el.getAttribute("data-dblclick") == 1) {
          this.handlePlayPause();
        }
        el.removeAttribute("data-dblclick");
      }, 300);
    }
  }

  public getStartTime(): any {
    this.startTimeFromSearchParams = +this.getStartTimeFromSearchParams();

    if (this.isReduced) {
      if (
        this.reducedVideoStartTime <= this.startTimeFromSearchParams &&
        this.reducedVideoStartTime + this.reducedVideoDuration > this.startTimeFromSearchParams
      ) {
        this.reducedVideoStartTime = this.startTimeFromSearchParams;

        return this.reducedVideoStartTime;
      } else {
        return this.reducedVideoStartTime || 0;
      }
    } else {
      return this.startTimeFromSearchParams;
    }
  }

  private getStartTimeFromSearchParams(): any {
    if (typeof URLSearchParams === "function") {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get("t") || 0;
    } else {
      return 0;
    }
  }

  /** @deprecated use showControlsBar **/
  handleMouseOver(): void {
    clearTimeout(this.controlsContainerTimer);
    this.container.addClass("mouse-over");
  }

  /** @deprecated use hideControlsBar **/
  handleMouseOut(): void {
    clearTimeout(this.controlsContainerTimer);
    if (!(this.video.paused || this.video.ended)) {
      this.container.removeClass("mouse-over");
    }
  }

  seek(seconds): void {
    let newTime = this.video.currentTime + seconds;
    if (newTime < 0) {
      newTime = 0;
    }
    if (newTime > this.video.duration) {
      newTime = this.video.duration;
    }
    this.video.currentTime = newTime;
  }

  showControlsBar(autoHideTime): void {
    clearTimeout(this.controlsContainerTimer);
    this.container.addClass("mouse-over");
    if (autoHideTime) {
      this.controlsContainerTimer = window.setTimeout(() => this.hideControlsBar(), autoHideTime);
    }
  }

  hideControlsBar(): void {
    clearTimeout(this.controlsContainerTimer);
    if (!(this.video.paused || this.video.ended)) {
      this.container.removeClass("mouse-over");
    }
  }

  uiRenderPlay(): void {
    this.playPauseBtn.html(IconPause.getHtml());
    this.container.removeClass("is-paused").addClass("is-playing");
    this.playPauseBtn.removeClass("is-paused").addClass("is-playing");
    this.playPauseBtnWrapper.attr("data-tooltip", this.lang.get("pause"));
    this.playButtonOverlay.hide();

    if (this.firstStart) {
      this.poster.hide();
      this.playButtonOverlay.hide();
      this.firstStart = false;
    }

    // Check how this param works for iOS 13
    if (this.touchModeEnabled) {
      this.showControlsBar(4000);
    }

    if (this.vastHandler.isActive) {
      this.playButtonOverlay.hide();
    }
  }

  uiRenderPause(): void {
    // Disable pause layout while VAST is playing
    if (this.vastHandler && this.vastHandler.isActive) {
      this.playButtonOverlay.hide();
      this.renderPauseIcon();

      return;
    }

    if (this.playerState.state.value === "LOADING") {
      return;
    }

    this.renderPauseIcon();
  }

  public renderPauseIcon(): void {
    this.playPauseBtn.html(IconPlay.getHtml());
    this.container.removeClass("is-playing").addClass("is-paused");
    this.playPauseBtn.removeClass("is-playing").addClass("is-paused");
    this.playPauseBtnWrapper.attr("data-tooltip", this.lang.get("play"));
    const event = new CustomEvent("html5PlayerOnPause");
    window.dispatchEvent(event);
  }

  public isAutoplay(): boolean {
    return (!this.options.autoplay.enabled && this.options.autoplay.active) || this.settings.autoplay.active;
  }

  public uiRenderError(error: string): void {
    if (!this.errorBox) {
      this.log("[ERROR] - " + error);
      this.errorBox = new DOMHelp()
        .create("div")
        .addClass("video-error")
        .text(error);
      this.container.add(this.errorBox);
    } else {
      error ? this.errorBox.text(error) : this.errorBox.remove();
    }
  }

  private uiRenderBuffering(): void {
    if (!this.bufferingSpinnerWrapper) {
      this.bufferingSpinnerWrapper = new DOMHelp().create("div").addClass("video-buffering-spinner-wrapper");
      this.container.add(this.bufferingSpinnerWrapper);
      this.bufferingSpinner = new DOMHelp().create("div").addClass("video-buffering-spinner");
      this.bufferingSpinnerWrapper.add(this.bufferingSpinner);
      this.playerState.state.subscribe(state => this.uiToggleBuffering(state === PLAYER_STATE.LOADING));
    }
  }

  public uiToggleBuffering(show: boolean): void {
    show ? this.bufferingSpinner.show() : this.bufferingSpinner.hide();
  }

  handleHLSNetworkError(): void {
    const videoId: string = this.qualitySelector.activeSource.src;
    const cdnNodeIp: string = this.getHostnameFromUrl();
    const quality: string = this.qualitySelector.activeSource.desc;
    const debugUrl = this.options.debugUrl;

    const errorObject: ErrorObject = {
      errorCode: 4,
      errorMessage:
        "The video could not be loaded, either because the server or network failed or because the format is not supported.",
      videoId: videoId,
      videoQuality: quality,
      cdnNodeIp: ""
    };

    const errorStorage = new ErrorStorage();
    // const previousErrors = errorStorage.get();

    this.bufferingSpinnerWrapper.hide();
    this.uiRenderError(
      "The video could not be loaded, either because the server or network failed or because the format is not supported."
    );

    if (!errorStorage.isReported(errorObject)) {
      errorStorage.add(errorObject);
      errorStorage.update();

      this.http
        .post({
          url: debugUrl,
          params: errorObject,
          headers: {
            "Content-type": "application/x-www-form-urlencoded"
          }
        })
        .catch(errorMessage => {
          this.log(errorMessage);
        });
    }
  }

  private getHostnameFromUrl(): string {
    const a = document.createElement("a");
    a.href = this.qualitySelector.activeSource.src;

    return a.hostname;
  }

  handleError(): void {
    const error: string = this.playerHandler.getError();

    if (
      (/firefox/i.test(navigator.userAgent) && /An unknown error occurred/gi.test(error)) ||
      error === "You aborted the video playback."
    ) {
      return;
    }

    this.uiRenderError(error);

    const debugUrl = this.options.debugUrl;

    if (debugUrl) {
      const errorObject: ErrorObject = this.playerHandler.getErrorObject();

      // Add params
      // errorObject['videoId'] = videoUrl;
      // errorObject['cdnNodeIp'] = cdnNodeIp;

      const errorStorage = new ErrorStorage();
      // const previousErrors = errorStorage.get();

      if (!errorStorage.isReported(errorObject)) {
        errorStorage.add(errorObject);
        errorStorage.update();

        this.http
          .post({
            url: debugUrl,
            params: errorObject,
            headers: {
              "Content-type": "application/x-www-form-urlencoded"
            }
          })
          .catch(errorMessage => {
            this.log(errorMessage);
          });
      }
    }
  }

  log(message): void {
    const isFirefox: boolean = /firefox/i.test(navigator.userAgent);

    if (isFirefox && /An unknown error occurred/gi.test(message)) {
      return;
    }

    console.log("[PLAYER LOG]:", message);
  }
}

(<any>window).VideoPlayer = VideoPlayer;
