import DOMHelp from "./utils/html";
import PLAYER_STATE from "./PlayerHandler/PlayerState";
import { IconPlay } from "./utils/icons";

export default class PlayQualityOverlay {
  public overlayWrapper: any;
  private isRendered: boolean = false;

  constructor(private player: any) {
    this.player = player;

    this.player.video.addEventListener("pause", () => this.show());
    this.player.video.addEventListener("playing", () => this.hide());
  }

  render(): void {
    if (this.isRendered) {
      return;
    }

    // element creation
    this.overlayWrapper = new DOMHelp().create("div").addClass("video-play-overlay");
    const buttonWrapper = new DOMHelp().create("div").addClass("overlay-play-button-wrapper");
    const overlayPlayButton = new DOMHelp().create("button").addClass("overlay-play-button");

    buttonWrapper.add(overlayPlayButton);
    overlayPlayButton.html(IconPlay.getHtml());

    // functions initiators
    this.overlayWrapper.on("click", e => this.closeAndPlay(e));

    //in player render
    this.overlayWrapper.add(buttonWrapper);
    this.player.container.add(this.overlayWrapper);

    this.isRendered = true;
  }

  closeAndPlay(e): void {
    if (this.player.chromecast.isCasting()) {
      return;
    }
    // this.overlayWrapper.remove();
    this.player.handlePlayPause(e);
  }

  show(): void {
    if (!this.player.ads.enabled || this.player.firstStart) {
      if (!this.player.vastHandler.isActive) {
        this.render();
      } else {
        if (!this.player.playerState.currentTime.getValue()) {
          this.render();
        }
      }
    }
  }

  hide(): void {
    if (this.player.playerState.state.getValue() !== PLAYER_STATE.PLAYING) {
      return;
    }

    if (typeof this.overlayWrapper !== "undefined") {
      this.overlayWrapper.remove();
    }
  }
}
