import DOMHelp from "./utils/html";
import { IconCogWheel } from "./utils/icons";
import ImagePreview from "./settings/ImagePreview";
import Autoplay from "./settings/Autoplay";

export default class Settings {
  public cogEnabled: boolean = false;

  private wrapper: any;
  private settingsBtnWrapper: any;
  private settingsBtn: any;
  private settingsHD: any;
  private settingsWrapper: any;
  private settingsQualityWrapper: any;
  private settingsPlayRateWrapper: any;
  private backFromPlayRate: any;
  private backFromQuality: any;
  private playbackRateTitle: any;
  private qualityButton: any;
  private speedButton: any;
  private hideThumbs: any;
  private autoplay: any;

  constructor(private player: any) {
    this.render();
  }

  render(): void {
    this.wrapper = new DOMHelp().create("div").addClass("settings-wrapper");
    this.wrapper.on("click", e => e.stopPropagation());

    this.settingsBtnWrapper = new DOMHelp()
      .create("div")
      .addClass("settings-button-wrapper")
      .addClass("tooltip-button")
      .attr("data-tooltip", this.player.lang.get("settings"));

    this.settingsBtn = new DOMHelp().create("button").html(IconCogWheel.getHtml());
    this.settingsHD = new DOMHelp()
      .create("span")
      .addClass("settings-hd")
      .text("HD")
      .hide();

    this.settingsBtnWrapper.add(this.settingsBtn).add(this.settingsHD);

    // Main Settings

    this.settingsWrapper = new DOMHelp()
      .create("div")
      .addClass("video-settings-wrapper")
      .hide();

    this.renderMainSettings();

    this.settingsBtnWrapper.on("click", () => {
      if (this.player.playerState.isAdvertise.getValue()) {
        return;
      }

      this.cogEnabled = !this.cogEnabled;

      if (this.cogEnabled) {
        this.settingsBtn.addClass("cog-active");
      } else {
        this.settingsBtn.removeClass("cog-active");
      }

      this.updateRateQualityButtons();
      this.toggleSettings();
    });
    document.addEventListener("click", () => {
      this.settingsWrapper.hide();
    });

    // Video Quality Settings
    this.renderQualitySettings();

    // Video Playback Rate Settings
    this.renderPlayrateSettings();

    // On Click outside of settings
    document.addEventListener("click", () => {
      this.settingsBtn.removeClass("cog-active");
      this.settingsWrapper.hide();
      this.settingsQualityWrapper.hide();
      this.settingsPlayRateWrapper.hide();
    });

    // Wrapper render
    this.wrapper
      .add(this.settingsBtnWrapper)
      .add(this.settingsWrapper)
      .add(this.settingsQualityWrapper)
      .add(this.settingsPlayRateWrapper);

    // Settings Switcher
    this.backFromPlayRate.on("click", () => {
      this.settingsPlayRateWrapper.hide();
      this.updateRateQualityButtons();
      this.settingsWrapper.show();
    });

    this.backFromQuality.on("click", () => {
      this.settingsQualityWrapper.hide();
      this.updateRateQualityButtons();
      this.settingsWrapper.show();
    });

    // Settings Switcher END
    this.toggleHDTag();
  }

  getSpeedName(): any {
    this.playbackRateTitle = this.player.playbackRate.getCurrentSpeed();

    if (this.playbackRateTitle === 1) {
      return this.player.lang.get("speedNormal");
    } else {
      return this.playbackRateTitle;
    }
  }

  renderMainSettings(): void {
    const line = [];

    this.qualityButton = new DOMHelp()
      .create("button")
      .addClass("actual-quality")
      .text(this.player.qualitySelector.getSourceTitle());
    this.speedButton = new DOMHelp()
      .create("button")
      .addClass("actual-speed")
      .text(this.getSpeedName());

    line[0] = new DOMHelp()
      .create("li")
      .add(
        new DOMHelp()
          .create("span")
          .addClass("label-quality")
          .text(this.player.lang.get("quality"))
      )
      .add(this.qualityButton);
    line[1] = new DOMHelp()
      .create("li")
      .add(
        new DOMHelp()
          .create("span")
          .addClass("label-speed")
          .text(this.player.lang.get("speed"))
      )
      .add(this.speedButton);

    this.settingsWrapper.add(
      new DOMHelp()
        .create("ul")
        .add(this.renderHideThumbs())
        .add(this.renderAutoplay())
        .add(line[0])
        .add(line[1])
    );

    this.qualityButton.on("click", () => {
      this.settingsWrapper.hide();
      this.settingsQualityWrapper.show();
    });

    this.speedButton.on("click", () => {
      this.settingsWrapper.hide();
      this.settingsPlayRateWrapper.show();
    });
  }

  updateRateQualityButtons(): void {
    this.qualityButton.text(this.player.qualitySelector.getSourceTitle());
    this.speedButton.text(this.getSpeedName());
  }

  renderHideThumbs(): any {
    this.hideThumbs = new ImagePreview(this.player);
    return this.player.touchModeEnabled ? null : this.hideThumbs.getBtn();
  }

  renderAutoplay(): any {
    this.autoplay = new Autoplay(this.player);
    return this.autoplay.getBtn();
  }

  renderQualitySettings(): void {
    this.settingsQualityWrapper = new DOMHelp()
      .create("div")
      .addClass("video-quality-settings-wrapper")
      .hide();

    this.backFromQuality = new DOMHelp()
      .create("button")
      .addClass("quality-back-button")
      .text(this.player.lang.get("settings"));

    const lineQuality = [];

    lineQuality[0] = new DOMHelp()
      .create("li")
      .addClass("back-button-wrapper")
      .add(this.backFromQuality);
    lineQuality[1] = new DOMHelp()
      .create("li")
      .addClass("inner-type-settings-wrapper")
      .add(this.player.qualitySelector.getRenderedSelector());

    this.settingsQualityWrapper.add(
      new DOMHelp()
        .create("ul")
        .add(lineQuality[0])
        .add(lineQuality[1])
    );
  }

  renderPlayrateSettings(): void {
    this.settingsPlayRateWrapper = new DOMHelp()
      .create("div")
      .addClass("video-playback-settings-wrapper")
      .hide();
    this.backFromPlayRate = new DOMHelp()
      .create("button")
      .addClass("playrate-back-button")
      .text(this.player.lang.get("settings"));

    const linePlayRate = [];

    linePlayRate[0] = new DOMHelp()
      .create("li")
      .addClass("back-button-wrapper")
      .add(this.backFromPlayRate);
    linePlayRate[1] = new DOMHelp()
      .create("li")
      .addClass("inner-type-settings-wrapper")
      .add(this.player.playbackRate.getRenderedSelector());

    this.settingsPlayRateWrapper.add(
      new DOMHelp()
        .create("ul")
        .add(linePlayRate[0])
        .add(linePlayRate[1])
    );
  }

  getSettingsWrapper(): any {
    return this.wrapper;
  }

  toggleSettings(): void {
    if (
      this.settingsWrapper.isVisible() ||
      this.settingsPlayRateWrapper.isVisible() ||
      this.settingsQualityWrapper.isVisible()
    ) {
      this.settingsWrapper.hide();
      this.settingsPlayRateWrapper.hide();
      this.settingsQualityWrapper.hide();
    } else {
      this.settingsWrapper.show();
    }
  }

  toggleHDTag(): void {
    if (parseInt(this.player.qualitySelector.getSourceTitle(), 10) >= 720) {
      this.settingsHD.show();
    } else {
      this.settingsHD.hide();
    }
  }
}
