import DOMHelp from "./utils/html";
import PlayerHandler, { default as PlayerHandlerRemote } from "./PlayerHandler/PlayerHandlerRemote";
import PlayerHandlerLocal from "./PlayerHandler/PlayerHandlerLocal";

const PLAYER_STATE = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
  STOPPED: "STOPPED",
  ERROR: "ERROR"
};

declare const chrome: any;
declare const cast: any;

export default class Chromecast {
  private apiInitialized: boolean = false;
  private hasReceiver: boolean = false;
  private apiSession: any;

  // help  https://github.com/googlecast/CastVideos-chrome/blob/master/CastVideos.js
  public tryAgainTimer: number;
  public wrapper: any;
  public btn: any;
  public remotePlayer: any;
  public playerState: any;
  public timer: any;
  public remotePlayerController: any;
  public playerHandler: any;
  public castSession: any;
  public currentMediaTime: any;

  constructor(public player: any) {
    this.playerHandler = new PlayerHandlerOld(this);
    this.tryAgainTimer = 1000;

    if (!this.player.options.chromeCastEnabled) {
      return;
    }
    // <button id="chromecast" is="google-cast-button" type="button" style="width: 50px;height: 50px" >Chromecast</button>
    // this.btn = new DOMHelp().create('button').addClass('chromecast').hide();
    this.wrapper = new DOMHelp()
      .create("div")
      .addClass(
        "chromecast-button-wrapper"
      ); /*.addClass('tooltip-button').attr('data-tooltip', 'Chromecast'); TODO doresit kdy zobrazovat*/
    this.btn = new DOMHelp(
      // "button"
      (<any>document).createElement("google-cast-launcher")
    ).text("");
    this.player.controlsBottomRight.add(this.wrapper.add(this.btn));

    this.remotePlayer = null;
    this.timer = null;
    this.isAvailable();
  }

  isAvailable(): void {
    try {
      if (typeof cast !== "undefined" && chrome.cast && chrome.cast.isAvailable) {
        // this.player.log("chromecast API isAvailable: true");
        this.initializeApi();
      } else {
        // this.player.log("chromecast API isAvailable: false");
        setTimeout(() => {
          this.isAvailable();
          this.tryAgainTimer = this.tryAgainTimer * 2;
        }, this.tryAgainTimer);
      }
    } catch (error) {
      this.player.log(error);
    }
  }

  initializeApi(): void {
    cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.PAGE_SCOPED
    });

    this.remotePlayer = new cast.framework.RemotePlayer();
    this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      this.switchPlayer.bind(this)
    );

    this.player.playerState.isCasting.subscribe(isCasting => {
      this.player.titleText.text(
        isCasting
          ? `${this.player.lang.get("casting")} ${this.castSession.getCastDevice().friendlyName}`
          : this.player.options.title
      );
    });
  }

  switchPlayer(): void {
    this.player.playerState.state.next(PLAYER_STATE.IDLE);
    let castSession = cast.framework.CastContext.getInstance().getCurrentSession();
    this.castSession = castSession;

    if (cast && cast.framework) {
      // this.toggleOverlay(this.remotePlayer.isConnected);

      if (this.remotePlayer.isConnected && castSession) {
        // this.wrapper.show();
        // this.btn.show();
        this.setupRemotePlayer(castSession);
      } else {
        this.stopSession();
      }

      return;
    }
  }

  toggleOverlay(visible): void {
    // document.getElementById('chromecasting-overlay').style.display = visible ? 'block' : 'none';
    if (visible) {
      this.player.container.addClass("chromecasting");
    } else {
      this.player.container.removeClass("chromecasting");
    }
  }

  setupRemotePlayer(castSession): void {
    this.castSession = castSession;
    // this.castSimple();

    // Add event listeners for player changes which may occur outside sender app
    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      () =>
        this.remotePlayer.isPaused
          ? this.player.playerState.state.next(PLAYER_STATE.PAUSED)
          : this.player.playerState.state.next(PLAYER_STATE.PLAYING)
    );

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, () => {
      this.player.playerHandler.playerState.currentTime.next(this.remotePlayer.currentTime);
      if (this.player.playerState.isCasting.getValue() !== true) {
        this.player.playerState.isCasting.next(true);
      }
      if (this.player.playerState.state.getValue() !== PLAYER_STATE.PLAYING && !this.remotePlayer.isPaused) {
        this.player.playerState.state.next(PLAYER_STATE.PLAYING);
      }
      setTimeout(() => this.incrementMediaTimeHandler(), 1000);
    });

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, () =>
      this.player.playerState.isMuted.next(this.remotePlayer.isMuted)
    );

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, () =>
      this.player.playerState.volume.next(this.remotePlayer.volumeLevel)
    );

    // This object will implement PlayerHandler callbacks with
    // remotePlayerController, and makes necessary UI updates specific
    // to remote playback
    let playerTarget = {} as any;

    playerTarget.play = () => this.play();
    playerTarget.pause = () => this.pause();
    playerTarget.stop = () => this.stop();
    playerTarget.load = () => this.cast();
    playerTarget.getCurrentMediaTime = () => this.getCurrentMediaTime();
    playerTarget.getMediaDuration = () => this.getMediaDuration();
    playerTarget.updateDisplayMessage = () => this.updateDisplayMessage();
    playerTarget.mute = () => this.mute();
    playerTarget.unMute = () => this.unMute();
    playerTarget.isMuted = () => this.isMuted();
    playerTarget.seekTo = time => this.seekTo(time);

    this.playerHandler.setTarget(playerTarget);
    this.player.playerHandler.stop();

    this.player.playerHandler = new PlayerHandlerRemote(
      this.remotePlayer,
      this.remotePlayerController,
      this.player.playerState
    );

    if (this.remotePlayer.isMuted) {
      this.playerHandler.mute();
    }

    this.playerHandler.play();
  }

  cast(): void {
    this.player.playerState.state.next(PLAYER_STATE.LOADING);

    this.castSession = cast.framework.CastContext.getInstance().getCurrentSession();

    const mediaInfo = new chrome.cast.media.MediaInfo(this.player.qualitySelector.getSource(), "video/mp4");

    mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
    mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
    mediaInfo.metadata.title = document.title;
    mediaInfo.metadata.images = [{ url: this.player.options.poster }];

    let request = new chrome.cast.media.LoadRequest(mediaInfo);

    this.castSession.loadMedia(request);
  }

  incrementMediaTimeHandler(): void {
    const currentMediaTime = this.remotePlayer.currentTime;
    const currentMediaDuration = this.remotePlayer.duration;

    if (this.remotePlayer.playerState === null) {
      this.stopCasting();
      return;
    }

    if (currentMediaDuration === 0) {
      return;
    }

    if (this.player.playerState.state.getValue() === PLAYER_STATE.PLAYING) {
      if (currentMediaTime >= currentMediaDuration) {
        this.stopCasting();
      } else {
        if (this.player.playerState.isCasting.getValue() !== true) {
          this.player.playerState.isCasting.next(true);
        }
      }
    }
  }

  stopCasting(): void {
    if (!this.player.options.chromeCastEnabled) {
      return;
    }

    clearInterval(this.timer);
    this.remotePlayerController.stop();

    try {
      cast.framework.CastContext.getInstance()
        .getCurrentSession()
        .endSession(true);
    } catch (e) {
      this.player.log(`[WARN] ${e}`);
    }

    this.toggleOverlay(false);
    this.player.playerState.state.next(PLAYER_STATE.IDLE);
    this.player.playerState.isCasting.next(false);
  }

  stopSession(): void {
    this.pause();
    this.currentMediaTime = 0;
    clearInterval(this.timer);
    this.player.playerState.state.next(PLAYER_STATE.IDLE);
    this.player.playerState.isCasting.next(false);
    this.player.volume.setupDefaultState();
    this.player.playerHandler = this.player.playerHandlerLocal;
  }

  isCasting(): any {
    if (this.remotePlayer === null) return false;
    return this.remotePlayer.isConnected && this.player.playerState.state.getValue() !== PLAYER_STATE.IDLE;
  }

  castError(error): string {
    switch (error.code) {
      case chrome.cast.ErrorCode.API_NOT_INITIALIZED:
        return "The API is not initialized." + (error.description ? " :" + error.description : "");
      case chrome.cast.ErrorCode.CANCEL:
        return "The operation was canceled by the user" + (error.description ? " :" + error.description : "");
      case chrome.cast.ErrorCode.CHANNEL_ERROR:
        return "A channel to the receiver is not available." + (error.description ? " :" + error.description : "");
      case chrome.cast.ErrorCode.EXTENSION_MISSING:
        return "The Cast extension is not available." + (error.description ? " :" + error.description : "");
      case chrome.cast.ErrorCode.INVALID_PARAMETER:
        return "The parameters to the operation were not valid." + (error.description ? " :" + error.description : "");
      case chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
        return (
          "No receiver was compatible with the session request." + (error.description ? " :" + error.description : "")
        );
      case chrome.cast.ErrorCode.SESSION_ERROR:
        return (
          "A session could not be created, or a session was invalid." +
          (error.description ? " :" + error.description : "")
        );
      case chrome.cast.ErrorCode.TIMEOUT:
        return "The operation timed out." + (error.description ? " :" + error.description : "");
    }
  }

  /** remote player handler **/

  play(): void {
    if (this.remotePlayer.isPaused) {
      this.player.playerState.state.next(PLAYER_STATE.PAUSED);
    } else {
      if (this.remotePlayer.isConnected) {
        this.player.playerState.state.next(PLAYER_STATE.PLAYING);
      }
    }
  }

  pause(): void {
    if (!this.remotePlayer.isPaused && this.remotePlayer.isConnected) {
      this.player.playerState.state.next(PLAYER_STATE.PLAYING);
    } else {
      if (this.remotePlayer.isConnected) {
        this.player.playerState.state.next(PLAYER_STATE.PAUSED);
      }
    }
  }

  stop(): void {
    try {
      this.castSession.endSession(true);
    } catch (e) {}
  }

  getCurrentMediaTime(): any {
    return this.remotePlayer.currentTime;
  }

  getMediaDuration(): any {
    return this.remotePlayer.duration;
  }

  updateDisplayMessage() {
    // console.log(this.playerState + ' on ' /*+ this.castSession.getCastDevice().friendlyName*/);
  }

  mute(): void {
    if (!this.remotePlayer.isMuted) {
      this.remotePlayerController.muteOrUnmute();
    }
  }

  unMute(): void {
    if (this.remotePlayer.isMuted) {
      this.remotePlayerController.muteOrUnmute();
    }
  }

  isMuted(): any {
    return this.remotePlayer.isMuted;
  }

  seekTo(time): void {
    this.remotePlayer.currentTime = time;
    this.remotePlayerController.seek();
  }
}

export class PlayerHandlerOld {
  public castPlayer: any;
  public target: any;
  public seeking: any;
  public videoChange: any;

  constructor(castPlayer) {
    this.castPlayer = castPlayer;
    this.target = {};
  }

  setTarget(target): void {
    this.target = target;
  }

  play(): void {
    if (
      this.castPlayer.playerState !== PLAYER_STATE.PLAYING &&
      this.castPlayer.playerState !== PLAYER_STATE.PAUSED &&
      this.castPlayer.playerState !== PLAYER_STATE.LOADED
    ) {
      this.load();
      return;
    }

    this.target.play();
    this.castPlayer.playerState = PLAYER_STATE.PLAYING;
    // do not fire play / pause events while seeking
    if (this.seeking) return;

    this.castPlayer.player.seeking = false;
    this.castPlayer.player.playing = true;
    // this.castPlayer.fireEvent('onPlay');
    this.castPlayer.videoChange = false;
    this.updateDisplayMessage();
  }

  pause(): void {
    if (this.castPlayer.playerState !== PLAYER_STATE.PLAYING) {
      return;
    }
    // do not fire play / pause events while seeking
    if (this.seeking) return;
    // do not react on paused events while changing media stream
    // the cake is a lie
    if (this.videoChange) return;

    this.castPlayer.player.playing = false;
    this.target.pause();
    // this.castPlayer.fireEvent('onPause');
    this.castPlayer.playerState = PLAYER_STATE.PAUSED;
    this.updateDisplayMessage();
  }

  stop(): void {
    this.target.stop();
    this.castPlayer.playerState = PLAYER_STATE.STOPPED;
    this.updateDisplayMessage();
  }

  load(): void {
    this.castPlayer.playerState = PLAYER_STATE.LOADING;
    this.target.load();
    this.updateDisplayMessage();
  }

  loaded(): void {
    // this.castPlayer.setActiveState(true);
    this.castPlayer.currentMediaDuration = this.getMediaDuration();
    this.castPlayer.playerState = PLAYER_STATE.LOADED;

    if (this.castPlayer.currentMediaTime > 0) {
      this.seekTo(this.castPlayer.currentMediaTime);
    }
    this.play();
    // this.castPlayer.startProgressTimer();
    this.updateDisplayMessage();
  }

  getCurrentMediaTime(): any {
    return this.target.getCurrentMediaTime();
  }

  getMediaDuration(): any {
    return this.target.getMediaDuration();
  }

  updateDisplayMessage(): void {
    this.target.updateDisplayMessage();
  }

  mute(): void {
    this.target.mute();
  }

  unMute(): void {
    this.target.unMute();
  }

  isMuted(): any {
    return this.target.isMuted();
  }

  seekTo(time): void {
    this.target.seekTo(time);
    this.updateDisplayMessage();
  }
}
