import DOMHelp from "./utils/html";
import { Source } from "./_config";
import PLAYER_STATE from "./PlayerHandler/PlayerState";
import Hls from "../../node_modules/hls.js";

export default class QualitySelector {
  private localStorageKey: string = "html5PlayerQuality";
  private sourceIndex: number = 0;
  private isHlsEnabled: boolean;
  private activeSource: Source = {
    src: "",
    desc: "",
    active: true,
    hls: false,
    format: "mp4"
  };
  private qualitySelector: DOMHelp;
  public isChangingQuality: boolean = false;

  constructor(private player: any) {
    this.setInitSource();
    this.render();
  }

  private setInitSource(): void {
    if (this.player.options.sources.length === 0) {
      return;
    }

    // Get active source from config
    for (let i = 0, max = this.player.options.sources.length; i < max; i++) {
      if (this.player.options.sources[i]["active"]) {
        this.activeSource = this.player.options.sources[i];
        break;
      }
    }

    // Set active source quality from config
    const savedQuality: string = this.player.storage.load(this.localStorageKey, null);
    const activeSourceQuality: string = this.activeSource["desc"];

    // Check HLS support
    this.isHlsEnabled = Hls.isSupported() || this.player.video.canPlayType("application/vnd.apple.mpegurl");

    // Update available sources in player config
    this.player.options.sources = this.player.options.sources.filter(
      (source: Source) => source["hls"] === this.activeSource["hls"]
    );

    if (this.player.options.sources.length === 0) {
      return;
    }

    // Get active source index
    const isSavedQualityAvailable = this.player.options.sources.some(
      (source: Source): boolean => source["desc"] === savedQuality
    );

    this.sourceIndex = isSavedQualityAvailable
      ? this.player.options.sources.map((source): Source[] => source["desc"]).indexOf(savedQuality)
      : this.player.options.sources.map((source): Source[] => source["desc"]).indexOf(activeSourceQuality);
    this.sourceIndex = this.sourceIndex !== -1 ? this.sourceIndex : 0;

    // Set active source
    this.activeSource = this.player.options.sources[this.sourceIndex];
  }

  public getSource(): string {
    return this.activeSource.src;
  }

  public getSourceTitle(): string {
    return this.activeSource.desc;
  }

  private render(): void {
    this.qualitySelector = new DOMHelp().create("ul").addClass("video-quality-menu");
    this.renderList();
  }

  private renderList(): void {
    // Render source quality list
    this.player.options.sources.forEach(
      (source: Source, index): void => {
        if (source["src"] && source["src"] !== "") {
          // Create HTML element
          const qualityValueElement: any = new DOMHelp().create("li").text(source["desc"]);

          // Get new available quality value
          const newQualityValue: number = index;

          // Add "click" event listener
          qualityValueElement.on("click", () => {
            this.changeQuality(newQualityValue);
          });

          // Set "active" status for active source quality value
          if (newQualityValue === this.sourceIndex) {
            qualityValueElement.addClass("active");
          }

          // Append quality value element to quality selector
          this.qualitySelector.add(qualityValueElement);
        }
      }
    );

    return;
  }

  private clearList(): void {
    this.qualitySelector.html("");
  }

  public getRenderedSelector(): DOMHelp {
    return this.qualitySelector;
  }

  private changeQuality(index: number): void {
    // Update isChangingQuality value
    this.isChangingQuality = true;

    // Get current time and player status
    const currentTime: string = this.player.video.currentTime;
    const isPlaying = this.player.playerState.state.getValue() === PLAYER_STATE.PLAYING;

    // Set new active source
    this.activeSource = this.player.options.sources[index];

    // Set new source index
    this.sourceIndex = index;

    // Pause player on quality change
    isPlaying && this.player.playerHandler.pause();

    // Hide video end overlay
    if (this.player.videoEndedOverlay) {
      this.player.videoEndedOverlay.hide();
    }

    // Set new active source
    if (this.activeSource["hls"]) {
      this.player.hls.destroy();
      this.player.hls = new Hls();
      this.player.hls.loadSource(this.activeSource["src"]);
      this.player.hls.attachMedia(this.player.video);
    } else {
      this.player.playerHandler.changeQuality(this.activeSource["src"]);
    }

    // Update quality value in localStorage
    this.player.storage.save(this.localStorageKey, this.activeSource["desc"]);

    // Reset playback rate
    this.player.playbackRate.reset();

    // Start playing video
    isPlaying && this.player.playerHandler.play();

    // Update player
    this.clearList();
    this.renderList();

    // Update quality selector UI
    this.player.settings.settingsWrapper.hide();
    this.player.settings.toggleHDTag();

    // Set video current time
    this.player.video.currentTime = currentTime;
  }
}
