/**
 * Player api for external scripts
 */

export default class Api {
  constructor(public player: any) {}

  /**
   * Return current video position in seconds
   * @returns {number}
   */
  public getCurrentTime(): number {
    return this.player.playerState.currentTime.getValue();
  }

  /**
   * Pause video
   */
  public pause(): void {
    this.player.playerHandler.pause();
  }

  /**
   * Play video
   */
  public play(): void {
    this.player.playerHandler.play();
  }
}
