import {VideoAdInterface} from "./_config";
import DOMHelp from "./utils/html";
import {IconBasket, IconCloseVideoAd} from "./utils/icons";
import StorageProvider from "./utils/Storage";
import PLAYER_STATE from "./PlayerHandler/PlayerState";

export default class VideoAd {
	private readonly config: VideoAdInterface;
	public desktopAdWrapper: any;
	public closeButtonWrapper: any;
	public storage: StorageProvider;
	private controlBar: any;

	constructor(private player: any) {
		this.config = this.player.options.videoAd;
		this.storage = new StorageProvider();

		this.hideDesktopAd = this.hideDesktopAd.bind(this);

		if (this.config.enabled) {
			this.init();
		}
	}

	init() {
		this.composeMobileAd();

		if (this.storage.load("_videoAdClosedTimestamp", 0) > +new Date()) {
			return;
		}

		if (!this.player.isMobile) {
			this.composeDesktopAd();
		}
	}

	composeDesktopAd() {
		const image = new DOMHelp("img")
			.attr("src", this.config.banner.image)
			.attr("width", "600");

		const imageLink = new DOMHelp("a")
			.attr("href", this.config.banner.url)
			.attr("target", "_blank")
			.addClass("desktop-spot")

		imageLink.add(image);

		const closeButtonWrapper = new DOMHelp("span")
			.addClass("desktop-spot-close-button")
			.html(IconCloseVideoAd.getHtml())
			.hide();

		const desktopAd = new DOMHelp("div")
			.addClass("desktop-spot")
			.add(imageLink)
			.add(closeButtonWrapper);

		this.desktopAdWrapper = new DOMHelp("div")
			.addClass("desktop-spot-wrapper")
			.add(desktopAd)
			.hide();

		this.player.container.add(this.desktopAdWrapper);

		this.closeButtonWrapper = document.querySelector(".desktop-spot-close-button");
		this.closeButtonWrapper.addEventListener("click", this.hideDesktopAd);


		const videoSub = this.player.playerState.state.subscribe(() => {
			if (this.player.playerState.state.getValue() === PLAYER_STATE.PLAYING) {
				this.setupDesktopAd();
				videoSub.unsubscribe();
			}
		});
	}

	setupDesktopAd() {
		setTimeout(() => {
			this.showDesktopAd();

			setTimeout(() => {
				this.closeButtonWrapper.style.display = "block";
			}, this.config.banner.showCloseBtnAfter * 1000);

			if (this.config.banner.hideAfter !== 0) {
				setTimeout(() => {
					this.desktopAdWrapper.hide();
				}, this.config.banner.hideAfter * 1000);
			}

		}, this.config.banner.displayAfter * 1000);
	}

	showDesktopAd() {
		this.desktopAdWrapper.show();
	}

	hideDesktopAd() {
		this.desktopAdWrapper.hide();
		this.closeButtonWrapper.removeEventListener("click", this.hideDesktopAd);
		this.storage.save("_videoAdClosedTimestamp", +new Date() + this.config.banner.disableAfterClose * 3600000);
	}

	composeMobileAd() {
		const controlBarAd = document.createElement('div');
		controlBarAd.className = "control-bar-ad-button";

		const link = document.createElement("a");
		link.href = this.config.button.url;
		link.target = "_blank";
		link.innerHTML = IconBasket.getHtml() + this.config.button.title;

		controlBarAd.appendChild(link);

		this.controlBar = document.querySelector('.control-bar-right-wrapper');
		this.controlBar.append(controlBarAd);
	}
}