import SettingsToggleItem from "./_settingsToggleItem";

export default class ImagePreview extends SettingsToggleItem {
  constructor(public player: any) {
    super(player);

    this.localStorageKey = "html5PlayerHideThumbs";
    this.config = this.player.options.imagePreview;

    if (this.config.enabled && this.player.options.thumbBase) {
      this.active = this.getDefaultState();
      this.render();
    }
  }

  render(): void {
    this.button = this.getToggleBtn("hide-thumbs-btn");
    this.buttonWrapper = this.getLine("label-hidethumbs", this.player.lang.get("imagePreview"), this.button);
    this.setState();
  }
}
