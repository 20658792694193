import PlayerHandler from "./PlayerHandler";
import PLAYER_STATE from "./PlayerState";
import PlayerStateProvider from "./PlayerStateProvider";

export default class PlayerHandlerLocal extends PlayerHandler {
  constructor(private video: HTMLMediaElement, public playerState: PlayerStateProvider, public player) {
    super(playerState);

    this.video.addEventListener("timeupdate", () => this.timeUpdate());

    this.video.addEventListener("loadedmetadata", () => {
      // Update player.isReduced
      this.player.isReduced =
        this.player.options.reduceVideo.enabled &&
        (this.player.qualitySelector.activeSource.src === this.video.src ||
          (this.player.options.hls &&
            this.player.isBrandPrerollShown &&
            this.video.src !== this.player.options.brandPreroll.url));

      // Update options if current video is reduced
      if (this.player.isReduced) {
        if (this.video.duration <= this.player.options.reduceVideo.duration) {
          this.player.reducedVideoDuration = this.video.duration;
          this.player.reducedVideoStartTime = 0;
        } else {
          this.player.reducedVideoDuration = this.player.options.reduceVideo.duration;

          if (
            this.player.options.reduceVideo.startTime + this.player.options.reduceVideo.duration >
            this.video.duration
          ) {
            this.player.reducedVideoStartTime = this.video.duration - this.player.options.reduceVideo.duration;
          } else {
            this.player.reducedVideoStartTime = this.player.options.reduceVideo.startTime;
          }
        }
      }

      this.timeUpdate();
      this.playerState.state.next(PLAYER_STATE.LOADED);
    });

    this.video.addEventListener("ended", () => this.playerState.state.next(PLAYER_STATE.ENDED));
    this.video.addEventListener(
      "pause",
      () => this.playerState.state.getValue() !== PLAYER_STATE.ERROR && this.playerState.state.next(PLAYER_STATE.PAUSED)
    );

    this.video.addEventListener("playing", () => {
      this.playerState.state.next(PLAYER_STATE.PLAYING);

      // Hide play button overlay on "PLAYING" event
      if (this.player.playButtonOverlay) {
        this.player.playButtonOverlay.hide();
      }
    });
    this.video.addEventListener("waiting", () => this.playerState.state.next(PLAYER_STATE.LOADING));
    this.video.addEventListener("error", () => this.playerState.state.next(PLAYER_STATE.ERROR));
  }

  public play(): Promise<any> {
    if (
      this.player.isReduced &&
      this.player.reducedVideoStartTime + this.player.reducedVideoDuration <= this.player.video.currentTime
    ) {
      this.player.video.currentTime = this.player.reducedVideoStartTime;
    }

    if (this.player.clickContextMenu.isActive) {
      this.player.clickContextMenu.isActive = false;
      return;
    }

    const playPromise = this.video.play();

    // ~~~> CONTEXT MENU UPDATE <~~~ //
    if (this.player.options.videoEndOverlay.enabled && this.player.videoEndedOverlay) {
      this.player.videoEndedOverlay.isRendered = false;
    }

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          this.playerState.state.next(PLAYER_STATE.PLAYING);

          if (this.player.playButtonOverlay) {
            this.player.playButtonOverlay.hide();
          }

          if (this.player.videoEndOverlay && this.player.videoEndedOverlay.enabled) {
            this.player.videoEndedOverlay.hide();
          }

          // do not fire play / pause events while seeking
          // if (this.errorBox) {
          //   this.errorBox.remove();
          // }
        })
        .catch(error => {
          // console.log(error, error.name);
        });
    } else {
      // show Error
    }

    return playPromise;
  }

  public pause(): void {
    if (!this.player.options.videoEndOverlay.enabled && !this.player.ads.enabled) {
      this.player.vastHandler.renderPlayButtonOverlay();
    }

    if (this.player.clickContextMenu.isActive) {
      this.player.clickContextMenu.isActive = false;
      return;
    }

    // if (this.player.video.currentTime === 0) {
    //   return;
    // }

    if (this.playerState.state.getValue() !== PLAYER_STATE.PLAYING) {
      return;
    }

    if (this.seeking) return;

    this.video.pause();

    // this.castPlayer.fireEvent('onPause');
    this.playerState.state.next(PLAYER_STATE.PAUSED);
  }

  public stop(): void {
    this.video.pause();
  }

  public load(): void {
    this.playerState.state.next(PLAYER_STATE.LOADING);
    this.video.load();
  }

  public seekTo(time): void {
    if (time >= 0) {
      this.video.currentTime = time;
    }
  }

  public mute(): void {
    this.video.muted = true;
  }

  public unMute(): void {
    this.video.muted = false;
  }

  public setVolume(volume: number): void {
    if (this.video && volume) {
      try {
        this.video.volume = volume;
      } catch (error) {
        this.player.log(error);
      }
    }
  }

  public changeQuality(source: string): void {
    const time = this.video.currentTime;
    this.video.src = source;
    this.video.currentTime = time;
  }

  public setNewVideo(source: string, time: number = 0) {
    this.video.src = source;
    this.video.currentTime = time;
  }

  public getError(): string {
    if (!this.video.error || this.video.error.code === 1) {
      return this.player.lang.get("MEDIA_ERR");
    }

    switch (this.video.error.code) {
      case this.video.error.MEDIA_ERR_ABORTED:
        return this.player.lang.get("MEDIA_ERR_ABORTED");
      case this.video.error.MEDIA_ERR_NETWORK:
        return this.player.lang.get("MEDIA_ERR_NETWORK");
      case this.video.error.MEDIA_ERR_DECODE:
        return this.player.lang.get("MEDIA_ERR_DECODE");
      case this.video.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        return this.player.lang.get("MEDIA_ERR_SRC_NOT_SUPPORTED");
      default:
        return this.player.lang.get("MEDIA_ERR");
    }
  }

  public getErrorObject(): ErrorObject {
    const videoId: string = this.player.qualitySelector.activeSource.src;
    const cdnNodeIp: string = this.getHostnameFromUrl();
    const quality: string = this.player.qualitySelector.activeSource.desc;

    if (!this.player.vastHandler.isShown) {
      return {
        errorCode: 2,
        errorMessage: this.player.lang.get("MEDIA_ERR"),
        videoId: this.player.vastHandler.vastFileURL,
        videoQuality: "",
        cdnNodeIp: ""
      };
    }

    if (!this.video.error) {
      return {
        errorCode: 0,
        errorMessage: this.player.lang.get("MEDIA_ERR"),
        videoId: videoId,
        videoQuality: quality,
        cdnNodeIp: cdnNodeIp
      };
    }

    if (this.video.error.code) {
      switch (this.video.error.code) {
        case this.video.error.MEDIA_ERR_ABORTED:
          return {
            errorCode: this.video.error.code,
            errorMessage: this.player.lang.get("MEDIA_ERR_ABORTED"),
            videoId: videoId,
            videoQuality: quality,
            cdnNodeIp: cdnNodeIp
          };
        case this.video.error.MEDIA_ERR_NETWORK:
          return {
            errorCode: this.video.error.code,
            errorMessage: this.player.lang.get("MEDIA_ERR_NETWORK"),
            videoId: videoId,
            videoQuality: quality,
            cdnNodeIp: ""
          };
        case this.video.error.MEDIA_ERR_DECODE:
          return {
            errorCode: this.video.error.code,
            errorMessage: this.player.lang.get("MEDIA_ERR_DECODE"),
            videoId: videoId,
            videoQuality: quality,
            cdnNodeIp: cdnNodeIp
          };
        case this.video.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          return {
            errorCode: this.video.error.code,
            errorMessage: this.player.lang.get("MEDIA_ERR_SRC_NOT_SUPPORTED"),
            videoId: videoId,
            videoQuality: quality,
            cdnNodeIp: cdnNodeIp
          };
        default:
          return {
            errorCode: this.video.error.code,
            errorMessage: this.player.lang.get("MEDIA_ERR"),
            videoId: videoId,
            videoQuality: quality,
            cdnNodeIp: cdnNodeIp
          };
      }
    } else {
      return {
        errorCode: 0,
        errorMessage: this.player.lang.get("MEDIA_ERR"),
        videoId: videoId,
        videoQuality: quality,
        cdnNodeIp: cdnNodeIp
      };
    }
  }

  private getHostnameFromUrl(): string {
    const a = document.createElement("a");
    a.href = this.player.qualitySelector.activeSource.src;

    return a.hostname;
  }

  private timeUpdate(): void {
    if (this.player.isReduced) {
      if (this.video.currentTime >= this.player.reducedVideoStartTime + this.player.reducedVideoDuration) {
        this.player.pause();
        this.player.playerState.state.next(PLAYER_STATE.ENDED);
      }
    }

    if (!isNaN(this.video.duration)) {
      this.playerState.currentTime.next(this.video.currentTime);
    }
  }
}

export interface ErrorObject {
  errorCode: number;
  errorMessage: string;
  videoId?: string;
  videoQuality?: string;
  cdnNodeIp?: string;
}
