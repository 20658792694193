import DOMHelp from "./utils/html";

export default class PlaybackRate {
  public speeds: number[];
  public currentSpeed: number;
  public speedSelector: any;

  constructor(private player: any) {
    this.speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];
    this.currentSpeed = 3;
    this.render();
  }

  render(): void {
    this.speedSelector = new DOMHelp().create("ul").addClass("video-playbackrate-menu");

    this.renderList();
  }

  renderList(): void {
    this.speeds.forEach((val, i) => {
      let text = val;

      if (val === 1) {
        text = this.player.lang.get("speedNormal");
      }

      const item = new DOMHelp().create("li").text(text.toString());
      item.on("click", () => this.changeSpeed(i));

      if (i === this.currentSpeed) {
        item.addClass("active");
      }

      this.speedSelector.add(item);
    });
  }

  clearList(): void {
    this.speedSelector.clearChilds();
  }

  getRenderedSelector(): any {
    return this.speedSelector;
  }

  videoSpeedUp(): void {
    //console.log(`currentSpeed: ${this.getCurrentSpeed()}`);

    //console.log(`Max number of speeds index usable: ${this.speeds.length - 1}`);

    if (this.currentSpeed === this.speeds.length - 1) {
      //console.log(`Speed already maximized.`);
    } else {
      this.changeSpeed(this.currentSpeed + 1);

      //console.log(`Speed changed to: ${this.getCurrentSpeed()}, index: speeds[${this.currentSpeed}]`);
    }
  }

  videoSpeedDown(): void {
    //console.log(`currentSpeed: ${this.getCurrentSpeed()}`);

    if (this.currentSpeed === 0) {
      //console.log(`Speed already minimized.`);
    } else {
      this.changeSpeed(this.currentSpeed - 1);

      //console.log(`Speed changed to: ${this.getCurrentSpeed()}, index: speeds[${this.currentSpeed}]`);
    }
  }

  changeSpeed(index): void {
    this.currentSpeed = index;
    this.player.video.playbackRate = this.speeds[index];

    this.clearList();
    this.renderList();
    this.player.settings.settingsWrapper.hide();
  }

  getCurrentSpeed(): any {
    return this.speeds[this.currentSpeed];
  }

  reset(): void {
    this.changeSpeed(3);
  }
}
