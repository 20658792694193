import DOMHelp from "./utils/html";
import { IconPlay } from "./utils/icons";

export default class VideoGesturesOverlay {
  private touchSeekDirection: any;
  private wrapper: any;
  private touchSeekPreviewElement: any;
  private touchSeekPreviewElementIconsWrapper: any;
  private touchSeekPreviewElementIconFirst: any;
  private touchSeekPreviewElementIconSecond: any;
  private touchSeekPreviewElementIconThird: any;
  private touchSeekPreviewElementText: any;
  private fullscreenTimer: number;

  constructor(private player: any) {
    this.touchSeekDirection = null;
    this.fullscreenTimer = +new Date();
    this.render();
  }

  render(): void {
    this.wrapper = new DOMHelp().create("div").addClass("gestures-events-overlay");

    this.player.container.add(this.wrapper);
    this.setupEvents();
  }

  setupEvents(): void {
    this.wrapper.clickCount = 0;
    this.wrapper.on("click", e => this.handleClick(e));
    this.wrapper.on("dblclick", e => this.handleMultiClick(e, 1));
  }

  handleClick(e): void {
    if (this.player.settings.settingsWrapper.isVisible()) {
      return;
    }

    clearTimeout(this.wrapper.clickTimeout);
    this.wrapper.clickCount++;

    this.wrapper.clickTimeout = setTimeout(() => {
      if (this.wrapper.clickCount === 1) {
        this.handleSingleClick(e);
      } else {
        this.handleMultiClick(e, this.wrapper.clickCount);
      }
      this.wrapper.clickCount = 0;
    }, 300);

    if (this.wrapper.clickCount > 1) {
      this.handleMultiClickProgress(e, this.wrapper.clickCount);
    }
  }

  handleMultiClickProgress(e, count): void {
    if (this.player.touchModeEnabled) {
      this.touchSeekPreview(e, count);
    }
  }

  handleMultiClick(e, count): void {
    if (this.player.touchModeEnabled) {
      this.touchSeek(count);
      // this.touchSeekDirection = null;
    } else {
      const timestamp: number = +new Date();

      if (timestamp - this.fullscreenTimer < 1000) {
        return;
      }

      this.fullscreenTimer = timestamp;
      this.player.fullscreen.handleFullscreen();
    }
  }

  handleSingleClick(e): void {
    if (this.player.touchModeEnabled) {
      if (this.player.container.hasClass("mouse-over")) {
        this.player.hideControlsBar();
      } else {
        this.player.showControlsBar(4000);
      }
    } else {
      this.player.handlePlayPause();
    }
  }

  touchSeekPreview(e, count): void {
    const half = this.wrapper.get().offsetWidth / 2;
    const direction = e.clientX < half ? "left" : "right";

    this.touchSeekDirection = direction;

    if (this.touchSeekDirection === null || this.touchSeekDirection === direction) {
      this.renderTouchSeekPreview(count, direction);
    } else {
      this.touchSeek(count);
      this.wrapper.clickCount = 0;
    }

    // this.touchSeekDirection = direction;
  }

  touchSeek(count): void {
    this.player.seek(this.getSeekTime(count, this.touchSeekDirection));

    try {
      if (this.touchSeekPreviewElement) {
        this.touchSeekPreviewElement.remove();
      }
      this.touchSeekPreviewElement = null;
    } catch (error) {
      console.log(error);
    }
  }

  getSeekTime(count, direction): any {
    let seekDuration = (count - 1) * 10;
    if (direction === "left") {
      seekDuration = seekDuration * -1;
    }

    return seekDuration;
  }

  renderTouchSeekPreview(count, direction): void {
    if (!this.touchSeekPreviewElement) {
      this.touchSeekPreviewElement = new DOMHelp().create("div").addClass("touch-seek-preview");
      this.wrapper.add(this.touchSeekPreviewElement);

      if (direction === "right") {
        this.touchSeekPreviewElement.addClass("right");
      } else {
        this.touchSeekPreviewElement.removeClass("right");
      }

      this.touchSeekPreviewElementIconsWrapper = new DOMHelp().create("div").addClass("icons-wrapper");
      this.touchSeekPreviewElementIconFirst = new DOMHelp().create("div").addClass("icon-single");
      this.touchSeekPreviewElementIconSecond = new DOMHelp().create("div").addClass("icon-single");
      this.touchSeekPreviewElementIconThird = new DOMHelp().create("div").addClass("icon-single");
      this.touchSeekPreviewElementIconFirst.html(IconPlay.getHtml());
      this.touchSeekPreviewElementIconSecond.html(IconPlay.getHtml());
      this.touchSeekPreviewElementIconThird.html(IconPlay.getHtml());

      this.touchSeekPreviewElementIconsWrapper
        .add(this.touchSeekPreviewElementIconFirst)
        .add(this.touchSeekPreviewElementIconSecond)
        .add(this.touchSeekPreviewElementIconThird);

      this.touchSeekPreviewElementText = new DOMHelp()
        .create("span")
        .text(`${this.getSeekTime(count, direction)} ${this.player.lang.get("seconds")}`);
      this.touchSeekPreviewElement.add(this.touchSeekPreviewElementIconsWrapper).add(this.touchSeekPreviewElementText);
    } else {
      this.touchSeekPreviewElementText.text(`${this.getSeekTime(count, direction)} ${this.player.lang.get("seconds")}`);
    }
  }
}
