export const defaultConfig: PlayerConfig = {
  width: 720,
  height: 405,
  maxPlayerHeight: 600, // previous value equal null
  minPlayerHeight: 350, // previous value equal 240 -> $ads-wrapper-max-height: 350px;
  reduceVideo: {
    enabled: false,
    startTime: 0,
    duration: 300
  },
  sources: [],
  poster: "",
  title: "",
  videoEndOverlay: {
    imageSize: {
      width: 240,
      height: 135
    },
    activeTab: 0,
    enabled: false,
    url: "",
    content: setData => {},
    shareClass: ""
  },
  thumbBase: "",
  imagePreview: {
    enabled: true,
    active: true,
    handleSave: active => {}
  },
  vttThumbnails: {
    enabled: false,
    url: "",
    urlHD: ""
  },
  autoplay: {
    enabled: true,
    active: false,
    handleSave: active => {}
  },
  widescreen: {
    enabled: false,
    active: false,
    handleAction: active => {},
    handleSave: active => {}
  },
  interstitialAd: {
    enabled: false,
    url1: "",
    url2: ""
  },
  thumbsCount: 15,
  chromeCastEnabled: true,
  logo: "",
  logoLink: "",
  language: "en",
  copyUrl: "",
  copyEmbedUrl: "",
  startTime: 0,
  brandPreroll: {
    enabled: false,
    url: "",
    viewLimit: 0
  },
  prerollAd: {
    enabled: false,
    vastURL: "",
    firstRoll: 1,
    rollPeriod: 1,
    skipDelay: 5
  },
  detailsButton: {
    enabled: false,
    selector: ""
  },
  onEndCallback: () => {},
  videoStats: {
    enabled: false,
    apiURL: ""
  },
  textAd: {
    enabled: false,
    text: "",
    url: "",
    displayAfter: 0,
    hideAfter: 10
  },
  videoAd: {
    enabled: false,
    button: {
      title: "",
      url: ""
    },
    banner: {
      displayAfter: 0,
      hideAfter: 0,
      showCloseBtnAfter: 0,
      image: "",
      url: "",
      disableAfterClose: 24
    }
  },
  showCopyLink: false,
  debugUrl: ""
};

export interface PlayerConfig {
  width: number;
  height: number;
  maxPlayerHeight: number;
  minPlayerHeight: number;
  reduceVideo: ReduceVideoInterface;
  sources: Source[];
  poster: string;
  title: string;
  videoEndOverlay: {
    imageSize: VideoEndOverlayImageSize;
    activeTab: number;
    enabled: boolean;
    url: string;
    content: (setData: () => object) => void;
    shareClass: string;
  };
  thumbBase: string;
  imagePreview: {
    enabled: boolean;
    active: boolean;
    handleSave: (active: boolean) => void;
  };
  vttThumbnails: VTTThumbnailsInterface;
  autoplay: {
    enabled: boolean /** If true => visible toggle in settings + override active from local storage **/;
    active: boolean /** Video autoplay setting, ignore if exist setting in local storage, can be forced by enabled false **/;
    handleSave: (
      active: boolean
    ) => void /** If is set local storage value is ignored, function called after user click on toggle **/;
  };
  widescreen: {
    enabled: boolean;
    active: boolean;
    handleAction: (active: boolean) => void;
    handleSave: (active: boolean) => void;
  };
  interstitialAd: {
    enabled: boolean;
    url1: string;
    url2: string;
  };
  thumbsCount: number;
  chromeCastEnabled: boolean;
  logo: string;
  logoLink: string;
  language: string;
  copyUrl: string;
  copyEmbedUrl: string;
  startTime: number;
  brandPreroll: BrandPrerollInterface;
  prerollAd: PrerollAddInterface;
  detailsButton: DetailsButton;
  showCopyLink: boolean;
  debugUrl: string;
  onEndCallback: () => void;
  videoStats: VideoStatsInterface;
  textAd: TextAdInterface;
  videoAd: VideoAdInterface;
}

export interface VideoEndOverlayImageSize {
  width: number;
  height: number;
}

export interface Source {
  src: string;
  desc: string;
  active: boolean;
  hls: boolean;
  format: string;
}

export interface BrandPrerollInterface {
  enabled: boolean;
  url: string;
  viewLimit?: number;
}

export interface PrerollAddInterface {
  enabled: boolean;
  vastURL: string;
  firstRoll: number;
  rollPeriod: number;
  skipDelay: number;
}

export interface ReduceVideoInterface {
  enabled: boolean;
  startTime: number;
  duration: number;
}

export interface DetailsButton {
  enabled: boolean;
  selector: string;
}

export interface VideoStatsInterface {
  enabled: boolean;
  apiURL: string;
}

export interface TextAdInterface {
  enabled: boolean;
  text: string;
  url: string;
  displayAfter: number;
  hideAfter: number;
}

export interface VideoAdInterface {
  enabled: boolean;
  button: VideoAdButtonInterface;
  banner: VideoAdBannerInterface;
}

export interface VideoAdButtonInterface {
  title: string;
  url: string;
}

export interface VideoAdBannerInterface {
  displayAfter: number;
  hideAfter: number;
  showCloseBtnAfter: number;
  image: string;
  url: string;
  disableAfterClose: number;
}

export interface VTTThumbnailsInterface {
  enabled: boolean;
  url: string;
  urlHD: string;
}
