import { VASTClient, VASTTracker } from "../../node_modules/@dailymotion/vast-client";
import Hls from "../../node_modules/hls.js";
import { BehaviorSubject } from "rxjs";
import PLAYER_STATE from "./PlayerHandler/PlayerState";
import DOMHelp from "./utils/html";
import { IconPlay } from "./utils/icons";

export default class VASTHandler {
  public isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isReadyToInit: boolean = false;
  private ads: any[] = [];
  private overlay: DOMHelp = null;
  private skipAdBtn: DOMHelp = null;
  private skipTime: number = 0;
  private vastClient: any = null;
  private vastTracker: any = null;
  private firstRollCounter: number = 0;
  private rollPeriodCounter: number = 0;
  private firstRollPrefix: string = "_vastFirstRoll";
  private rollPeriodPrefix: string = "_vastRollPeriod";
  public isActive: boolean = false;
  public isShown: boolean = false;
  public isVastSkipped: boolean = false;
  public isSkipButtonClicked: boolean = false;
  private isClicked: boolean = false;
  private skipAdBtnIsActive: boolean = true;
  private timer: any;
  private playButtonOverlayWrapper: any = null;
  public vastFileURL: string = "";

  constructor(private player: any) {
    if (
      player.options.prerollAd.enabled &&
      player.options.prerollAd &&
      typeof player.options.prerollAd === "object" &&
      player.options.prerollAd.vastURL &&
      this.isVASTAllowed()
    ) {
      this.isReadyToInit = true;
      this.fetchVastFile();
    } else {
      this.isLoaded.next(true);
      this.isShown = true;
      this.isVastSkipped = true;

      if (this.player.options.brandPreroll.enabled) {
        this.timer = setTimeout(() => {
          this.player.continueAfterVAST(true);
        }, 0);

        this.timer = null;
      }
    }

    this.player.playerState.isAdvertise.subscribe(
      active =>
        active ? this.player.container.addClass("playing-vast") : this.player.container.removeClass("playing-vast")
    );
  }

  private isVASTAllowed(): boolean {
    this.firstRollCounter = this.getFirstRoll();
    this.rollPeriodCounter = this.getRollPeriod();

    if (this.firstRollCounter < this.player.options.prerollAd.firstRoll) {
      this.firstRollCounter++;
      this.setFirstRoll(this.firstRollCounter);

      return false;
    }

    if (this.firstRollCounter === this.player.options.prerollAd.firstRoll) {
      this.firstRollCounter++;
      this.setFirstRoll(this.firstRollCounter);
      return true;
    }

    if (this.rollPeriodCounter >= this.player.options.prerollAd.rollPeriod) {
      this.rollPeriodCounter = -1;
      this.setRollPeriod(this.rollPeriodCounter);
    }

    if (this.rollPeriodCounter === -1) {
      this.rollPeriodCounter++;
      // this.setRollPeriod(this.rollPeriodCounter);
      return true;
    }

    this.rollPeriodCounter++;
    this.setRollPeriod(this.rollPeriodCounter);

    return false;
  }

  private setFirstRoll(value: any): void {
    VASTHandler.setCookie(this.firstRollPrefix, value, 0);
  }

  private setRollPeriod(value: any): void {
    VASTHandler.setCookie(this.rollPeriodPrefix, value, 0);
  }

  private static getCookie(name: string): string | null {
    const value: string = "; " + document.cookie;
    const parts: string[] = value.split("; " + name + "=");

    if (parts.length === 2) {
      return parts
        .pop()
        .split(";")
        .shift();
    }

    return null;
  }

  private static setCookie(name: string, value: any, hoursToExpire: number): void {
    let expires: string = "";

    if (hoursToExpire) {
      const expirationDate: number = +new Date() + hoursToExpire * 60 * 60 * 1000;
      expires = "; expires=" + new Date(expirationDate).toUTCString();
    }

    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
  }

  private getFirstRoll(): number {
    return +VASTHandler.getCookie(this.firstRollPrefix);
  }

  private getRollPeriod(): number {
    return +VASTHandler.getCookie(this.rollPeriodPrefix);
  }

  private fetchVastFile(): void {
    this.vastClient = new VASTClient();

    this.vastClient
      .get(this.player.options.prerollAd.vastURL, { withCredentials: true, timeout: 5000 })
      .then(res => {
        // Do something with the parsed VAST response
        this.ads = res.ads as any[];

        if (this.player.isAutoplay()) {
          this.setVideoAd();
        } else {
          this.setNextAdWatch();
        }

        this.isLoaded.next(true);
        this.isActive = true;
      })
      .catch(err => {
        this.player.log("VAST config loading error");

        // Show play button after VAST config error
        this.setRollPeriod(this.rollPeriodCounter);
        this.isReadyToInit = false;
        this.player.playButtonOverlay.show();

        this.isActive = false;

        // Update isSkipButtonClicked
        this.isSkipButtonClicked = true;

        // Update video title after skip
        this.player.titleText.text(this.player.options.title);

        // Show elements
        this.showUITopElements();
        this.player.isVASTComplete = false;
        this.player.isAdsEnabled = !this.player.options.videoEndOverlay.enabled;

        setTimeout(() => {
          this.player.playerState.state.next(PLAYER_STATE.ENDED);
        }, 50);

        if (this.player.playerState.state.getValue() === PLAYER_STATE.IDLE) {
          if (this.playButtonOverlayWrapper) {
            this.playButtonOverlayWrapper.show();
          } else {
            this.renderPlayButtonOverlay();
          }
        }

        if (this.player.isAutoplay()) {
          this.removePlayButton();
          setTimeout(() => {
            this.player.continueAfterVAST(false);
          }, 55);
        } else {
          this.player.continueAfterVAST(false);
        }
      });
  }

  private setVideoAd(): void {
    const ad = this.getNextAd();

    if (!ad) return;

    // Show elements
    this.hideUITopElements();

    this.player.titleText.text(ad.title);

    const adData = ad.creatives[0];

    if (adData && adData.mediaFiles[0]) {
      const currentTime = this.player.playerState.currentTime.getValue() || 0;

      if (this.player.isHlsEnabled) {
        this.player.hls.destroy();
      }

      this.vastFileURL = adData.mediaFiles[0].fileURL;

      const isHLS: boolean = this.vastFileURL.indexOf(".m3u8") !== -1;

      if (isHLS) {
        if (this.player.video.canPlayType("application/vnd.apple.mpegurl")) {
          // mobile/apple devices
          this.player.video.src = this.vastFileURL;
        } else if (Hls.isSupported()) {
          // desktop devices
          this.player.hls = new Hls();
          this.player.hls.loadSource(this.vastFileURL);
          this.player.hls.attachMedia(this.player.video);

          // Setup HLS error event handler
          this.player.hls.on(Hls.Events.ERROR, (event, data) => {
            const errorFatal = data.fatal;

            if (errorFatal) {
              this.player.playerState.state.next(PLAYER_STATE.ERROR);
            }

            switch (data.details) {
              case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                // ....
                break;
              default:
                break;
            }
          });
        }
      } else {
        this.player.playerHandler.setNewVideo(this.vastFileURL);
      }

      this.renderOverlay(adData);
      this.player.playerState.isAdvertise.next(true);
      this.vastTracker = new VASTTracker(this.vastClient, ad, ad.creatives[0]);

      // Setup VAST tracking events
      this.setupVastEvents();

      const skipCountSub = this.player.playerState.currentTime.subscribe(time => {
        this.refreshSkipCounter(time.toFixed(0));
        this.vastTracker.setProgress(time);
      });

      const adsEndSub = this.player.playerState.state.subscribe(state => {
        if (state === PLAYER_STATE.ENDED || state === PLAYER_STATE.ERROR) {
          if (state === PLAYER_STATE.ENDED) {
            this.setRollPeriod(this.rollPeriodCounter);
            this.isReadyToInit = false;
            this.isActive = false;
            this.vastTracker.complete(true);
            this.removePlayButton();
          }

          if (state === PLAYER_STATE.ERROR) {
            this.vastTracker.errorWithCode(405); // MEDIAFILE_PLAYBACK_ERROR
            this.player.uiRenderError("");
            // this.removePlayButton();
          }

          this.player.playerHandler.setNewVideo(this.player.qualitySelector.getSource(), currentTime);
          this.player.playerState.isAdvertise.next(false);

          this.skipTime = 0;
          this.overlay.remove();
          this.player.isVASTComplete = true;

          if (this.player.isAutoplay()) {
            setTimeout(() => {
              this.player.continueAfterVAST(false);
            }, 55);
          } else {
            this.player.continueAfterVAST(false);
          }

          // Update video title after complete
          this.player.titleText.text(this.player.options.title);

          // Show elements
          this.showUITopElements();

          this.player.playerHandler.play();
          adsEndSub.unsubscribe();
          skipCountSub.unsubscribe();
        }
      });

      // this.vastTracker.setPaused(false);

      // Track VAST impression
      this.vastTracker.trackImpression();
    }
  }

  private setupVastEvents() {
    // VASTTracker: Mute/Unmute events handler
    this.player.video.addEventListener("volumechange", e => {
      this.vastTracker.setMuted(e.target.muted);
    });

    // VASTTracker: Resume event handler
    this.player.video.addEventListener("play", () => {
      if (this.player.video.currentTime === 0) {
        return;
      }

      this.vastTracker.setPaused(false);
    });

    // VASTTracker: Pause event handler
    this.player.video.addEventListener("pause", () => {
      this.vastTracker.setPaused(true);
    });

    this.vastTracker.on("firstQuartile", () => {});
    this.vastTracker.on("midpoint", () => {});
    this.vastTracker.on("thirdQuartile", () => {});
  }

  private getNextAd(): any {
    const id: number = Math.floor(Math.random() * this.ads.length);
    return this.ads[id] ? this.ads[id] : null;
  }

  private setNextAdWatch(): void {
    const nextAdTime = 0;

    if (nextAdTime === 0) {
      this.setPreRollAd();
    } else {
      const videoTimeSub = this.player.playerState.currentTime.subscribe(time => {
        if (time >= nextAdTime && this.player.playerState.state.getValue() === PLAYER_STATE.PLAYING) {
          this.playNextAd();

          if (videoTimeSub) {
            videoTimeSub.unsubscribe();
          }
        }
      });
    }
  }

  private setPreRollAd(): void {
    const videoSub = this.player.playerState.state.subscribe(() => {
      if (this.player.playerState.state.getValue() === PLAYER_STATE.PLAYING) {
        this.playNextAd();

        if (videoSub) {
          videoSub.unsubscribe();
        }
      }
    });
  }

  private playNextAd(): void {
    this.player.playerHandler.pause();
    this.setVideoAd();
    this.player.playerHandler.play();
  }

  private renderOverlay(adData: any): void {
    this.skipTime = adData.skipDelay || this.player.options.prerollAd.skipDelay;

    if (this.skipTime > this.player.options.prerollAd.skipDelay) {
      this.skipTime = this.player.options.prerollAd.skipDelay;
    }

    if (this.skipTime) {
      this.skipAdBtn = new DOMHelp("button")
        .addClass("disabled")
        .hide()
        .on("click", () => this.skipAd());
      this.refreshSkipCounter();
    }

    this.overlay = new DOMHelp("div").addClass("vast-overlay").add([
      new DOMHelp("a")
        .attr("href", adData.videoClickThroughURLTemplate.url)
        .attr("target", "_blank")
        .on("click", () => this.handleAdClick()),
      this.skipAdBtn
    ]);

    this.player.container.add(this.overlay);

    this.setupPlayPauseEventsHandler();
  }

  private setupPlayPauseEventsHandler(): void {
    this.player.playerState.state.subscribe(state => {
      if (state === PLAYER_STATE.PLAYING) {
        this.player.video.style.removeProperty("display");

        if (this.skipAdBtn) {
          this.showSkipButton();
        }

        if (this.playButtonOverlayWrapper) {
          this.playButtonOverlayWrapper.hide();
        }
      }

      if (state === PLAYER_STATE.PAUSED && this.player.video.duration !== this.player.video.currentTime) {
        // Uncomment to hide skip ad button
        // this.hideSkipButton();

        if (this.playButtonOverlayWrapper) {
          this.playButtonOverlayWrapper.show();
        } else {
          this.renderPlayButton();
        }
      }

      state === PLAYER_STATE.ENDED && this.player.playButtonOverlay.hide();
    });
  }

  private hideSkipButton(): void {
    this.skipAdBtn.hide();
  }

  private showSkipButton(): void {
    this.skipAdBtn.show();
  }

  private refreshSkipCounter(currentTime: number = 0): void {
    // if (this.isClicked) {
    //   this.skipAdBtn.text(this.player.lang.get("skipVideoAd"));
    //   return;
    // }

    let counter = "";

    if (this.skipTime > 0) {
      const timeLeft = this.skipTime - currentTime;

      if (timeLeft > 0) {
        counter = `${timeLeft}`;
      } else {
        this.skipAdBtn.removeClass("disabled");
        this.setRollPeriod(this.rollPeriodCounter);
      }
    }

    if (this.skipAdBtnIsActive && +counter > 0) {
      this.skipAdBtn.text(`${this.player.lang.get("skipVideoAdAfter")} ${counter}`);
    } else {
      this.skipAdBtn.text(`${this.player.lang.get("skipVideoAd")}`);
    }

    if (this.skipTime - currentTime <= 0) {
      this.skipAdBtnIsActive = false;
    }
  }

  private canSkip(): boolean {
    if (this.skipTime > 0) {
      if (this.skipTime - this.player.playerState.currentTime.getValue().toFixed(0) <= 0) {
        return true;
      }
    }
    return false;
  }

  private handleAdClick(): void {
    // Comment the line bellow to disable pause on preroll ad click
    this.player.playerHandler.pause();

    this.isActive = true;

    this.skipAdBtn.removeClass("disabled").text(`${this.player.lang.get("skipVideoAd")}`);
    this.isClicked = true;

    // Track VAST click event
    this.vastTracker.click();

    // Show controls bar on ad click event
    // this.player.showControlsBar(4000);

    this.renderPlayButton();
  }

  public renderPlayButton(): void {
    if (!this.player.isMobile) {
      return;
    }

    this.renderPlayButtonOverlay();
  }

  renderPlayButtonOverlay() {
    // if (!this.playButtonOverlayWrapper) {
    //   this.playButtonOverlayWrapper = new DOMHelp().create("div").addClass("video-play-overlay");
    //   const buttonWrapper = new DOMHelp().create("div").addClass("overlay-play-button-wrapper");
    //   const overlayPlayButton = new DOMHelp().create("button").addClass("overlay-play-button");
    //
    //   buttonWrapper.add(overlayPlayButton);
    //   overlayPlayButton.html(IconPlay.getHtml());
    //
    //   // functions initiators
    //   this.playButtonOverlayWrapper.on("click", e => {
    //     this.playButtonOverlayWrapper.hide();
    //     this.player.playerHandler.play();
    //   });
    //
    //   //in player render
    //   this.playButtonOverlayWrapper.add(buttonWrapper);
    //   this.player.container.add(this.playButtonOverlayWrapper);
    // } else {
    //   this.playButtonOverlayWrapper.show();
    // }
  }

  public removePlayButton(): void {
    if (this.playButtonOverlayWrapper) {
      this.playButtonOverlayWrapper.remove();
    }
  }

  public skipAd(): void {
    // if (this.canSkip() || this.isClicked) {
    if (this.canSkip()) {
      this.isActive = false;

      // Update isSkipButtonClicked
      this.isSkipButtonClicked = true;

      // Update video title after skip
      this.player.titleText.text(this.player.options.title);

      // Show elements
      this.showUITopElements();

      this.vastTracker.skip();
      // this.player.playerHandler.pause();

      this.player.isVASTComplete = false;
      this.player.isAdsEnabled = !this.player.options.videoEndOverlay.enabled;

      this.removePlayButton();

      if (this.player.hls) {
        this.player.hls.destroy();
      }

      setTimeout(() => {
        this.player.playerState.state.next(PLAYER_STATE.ENDED);
      }, 50);
    }
  }

  private hideUITopElements(): void {
    this.player.titleWrapper.hide();
    this.player.copyLinkWrapper.hide();
  }

  private showUITopElements(): void {
    this.player.titleWrapper.show();
    this.player.copyLinkWrapper.show();
  }
}
