import DOMHelp from "../utils/html";

export default class SettingsToggleItem {
  public localStorageKey: string = "";
  public active: boolean = false;
  public config: any;
  public button: any;
  public buttonWrapper: any;

  constructor(public player: any) {
    this.player = player;
    this.config = {
      enabled: false,
      active: false,
      handleSave: active => {}
    };
    this.button = null;
    this.buttonWrapper = null;
  }

  getToggleBtn(className): any {
    const btn = new DOMHelp().create("button").addClass(className);
    btn.on("click", () => this.handleToggle());
    return btn;
  }

  getLine(className, title, toggleBtn): any {
    return new DOMHelp()
      .create("li")
      .add(
        new DOMHelp()
          .create("span")
          .addClass(className)
          .text(title)
      )
      .add(toggleBtn);
  }

  handleToggle(): void {
    this.active = !this.active;
    this.player.storage.save(this.localStorageKey, this.active, this.config.handleSave);
    this.setState();
  }

  setState(): void {
    if (this.active) {
      this.button.addClass("switch-btn-active");
    } else {
      this.button.removeClass("switch-btn-active");
    }
  }

  getBtn(): any {
    if (this.config.enabled) {
      return this.buttonWrapper;
    } else {
      return null;
    }
  }

  getDefaultState(): any {
    return this.player.storage.load(this.localStorageKey, this.config.active, this.config.handleSave);
  }
}
