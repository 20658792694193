import { mergeDeep } from "./deepMerge";
import EN from "../../lang/en.json";
import RU from "../../lang/ru.json";
import ES from "../../lang/es.json";
import DE from "../../lang/de.json";

const langList = {
  en: EN,
  ru: RU,
  es: ES,
  de: DE
};

export default class Lang {
  private texts: object = {};

  constructor(requestedLang) {
    this.setTextSource(requestedLang);
  }

  public get(key): any {
    return this.texts[key] || key;
  }

  private setTextSource(requestedLang): void {
    const newLang = langList[requestedLang] || EN;
    this.texts = mergeDeep(EN, newLang);
  }
}
