import DOMHelp from "./utils/html";
import PLAYER_STATE from "./PlayerHandler/PlayerState";

export default class Ads {
  private enabled: boolean = false;
  private wrapper: any;

  constructor(public player: any) {
    // this.enabled = this.player.options.ads.url1.length > 0;

    // Enable ads by default
    this.enabled = this.isAdsEnabledCheck();

    if (this.enabled) {
      this.player.video.addEventListener("pause", () => {
        if (
          !this.player.vastHandler.isActive &&
          this.player.vastHandler.isShown &&
          !this.player.clickContextMenu.isActive
        ) {
          this.show();
        }
      });
      this.player.video.addEventListener("playing", () => this.hide());
    }
  }

  isAdsEnabledCheck(): boolean {
    if (this.player.options.videoEndOverlay.enabled) {
      return true;
    }

    if (this.player.options.interstitialAd.enabled) {
      return !!this.player.options.interstitialAd.url1;
    } else {
      return false;
    }
  }

  render(): void {
    if (!this.player.isAdsEnabled) {
      this.player.isAdsEnabled = true;
      return;
    }

    this.wrapper = new DOMHelp().create("div").addClass("video-extra-overlay");
    const box = new DOMHelp().create("div").addClass("video-extra-wrapper");
    const btn = new DOMHelp()
      .create("button")
      .addClass("video-extra-close")
      .text(`${this.player.lang.get("close")} [X]`);

    box
      .add(new DOMHelp().create("p").text(this.player.lang.get("advertisement")))
      .add(btn)
      .add(this.getRenderedIframe(this.player.options.interstitialAd.url1));

    if (this.player.options.interstitialAd.url2) {
      box.add(this.getRenderedIframe(this.player.options.interstitialAd.url2));
      this.wrapper.addClass("video-extra-double");
    }

    btn.on("click", e => this.closeAndPlay(e));
    this.wrapper.on("click", e => this.closeAndPlay(e));

    box.add(
      new DOMHelp()
        .create("button")
        .text(this.player.lang.get("stopCast"))
        .addClass("chrome-cast-stop")
        .on("click", () => {
          this.player.chromecast.stopCasting();
        })
    );

    this.wrapper.add(box);
    this.player.container.add(this.wrapper);
  }

  getRenderedIframe(url): any {
    return new DOMHelp()
      .create("div")
      .addClass("video-extra-info")
      .add(
        new DOMHelp()
          .create("iframe")
          .attr("src", url)
          .attr("scrolling", "no")
        /*new DOMHelp().create('iframe').attr('src', url).attr('width', "300px").attr('height', "250px").attr('scrolling', 'no')*/
      );
  }

  closeAndPlay(e): void {
    if (this.player.chromecast.isCasting()) {
      return;
    }

    this.wrapper.remove();
    this.player.play();
  }

  hide(): void {
    if (typeof this.wrapper !== "undefined") {
      this.wrapper.remove();
    }
  }

  show(): void {
    if (this.player.video.currentTime === 0.0) {
      return;
    }

    if (this.player.isReduced && this.player.video.currentTime === this.player.reducedVideoStartTime) {
      return;
    }

    if (this.player.qualitySelector.isChangingQuality) {
      this.player.qualitySelector.isChangingQuality = false;
      return;
    }

    if (this.player.options.videoEndOverlay.enabled && this.player.options.videoEndOverlay.url) {
      if (this.player.videoEndedOverlay) {
        if (!this.player.videoEndedOverlay.contentLoaded) {
          this.player.videoEndedOverlay
            .loadContent()
            .then(response => {
              return JSON.parse(response);
            })
            .then(json => {
              this.player.videoEndedOverlay.setData(json);
              this.player.videoEndedOverlay.contentLoaded = true;
            })
            .then(() => {
              if (
                this.player.startTimeFromSearchParams !== 0 &&
                this.player.startTimeFromSearchParams === this.player.video.currentTime
              ) {
                this.player.isVASTComplete = false;
              }

              if (
                this.player.videoEndedOverlay.overlayEndWrapper &&
                this.player.playerState.state.getValue() !== PLAYER_STATE.PLAYING
              ) {
                if (
                  this.player.videoEndedOverlay.overlayEndWrapper &&
                  this.player.startTimeFromSearchParams !== this.player.video.currentTime
                ) {
                  this.player.videoEndedOverlay.overlayEndWrapper.show();
                  if (!this.player.isMobile) {
                    this.player.titleWrapper.hide();
                  }
                }

                this.player.videoEndedOverlay.updateVideoData();
                this.player.videoEndedOverlay.refreshRelated();
              }
            })
            .catch(e => {
              this.player.options.videoEndOverlay.enabled = false;
              this.player.log("VideoEndOverlay was switched off. Ads mode was turned on.");

              if (this.player.playerState.isAdvertise.getValue()) {
                return;
              }

              if (
                this.enabled &&
                this.player.firstStart === false &&
                this.player.playerState.state.getValue() !== PLAYER_STATE.ERROR
              ) {
                if (this.player.video.ended && this.player.options.videoEndOverlay.enabled) {
                  return;
                }

                this.render();
              }
            });
        } else {
          this.player.videoEndedOverlay.show();
          return;
        }
      }

      return;
    }

    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }

    if (
      this.enabled &&
      this.player.firstStart === false &&
      this.player.playerState.state.getValue() !== PLAYER_STATE.ERROR
    ) {
      if (this.player.video.ended && this.player.options.videoEndOverlay.enabled) {
        return;
      }

      this.render();
    }
  }
}
