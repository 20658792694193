import PlayerHandler from "./PlayerHandler";
import PLAYER_STATE from "./PlayerState";
import PlayerStateProvider from "./PlayerStateProvider";

declare const chrome: any;
declare const cast: any;

export default class PlayerHandlerRemote extends PlayerHandler {
  constructor(private remotePlayer: any, private remotePlayerController: any, public playerState: PlayerStateProvider) {
    super(playerState);
  }

  play(): void {
    console.log("Play ChromeCast", this.remotePlayer, this.remotePlayerController);
    // if (this.remotePlayer.isPaused) {
    this.remotePlayerController.playOrPause();
    // }
  }

  pause(): void {
    console.log("Pause ChromeCast", this.remotePlayer, this.remotePlayerController);
    // if (!this.remotePlayer.isPaused && this.remotePlayer.connected) {
    this.remotePlayerController.playOrPause();
    // }
  }

  load() {}

  public seekTo(time): void {
    this.remotePlayer.currentTime = time;
    this.remotePlayerController.seek();
  }

  public mute(): void {
    if (!this.remotePlayer.isMuted) {
      this.remotePlayerController.muteOrUnmute();
    }
  }

  public unMute(): void {
    if (this.remotePlayer.isMuted) {
      this.remotePlayerController.muteOrUnmute();
    }
  }

  public setVolume(volume: number): void {
    this.remotePlayer.volumeLevel = volume;
    this.remotePlayerController.setVolumeLevel();
  }

  public changeQuality(source: string): void {
    console.log("chromecast not support change quality!");
  }

  private timeUpdate() {
    // if (!isNaN(this.video.duration)) {
    //   this.playerState.currentTime.next(this.video.currentTime)
    // }
  }
}
