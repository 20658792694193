export default class DOMHelp {
  public element = null;

  constructor(element: string | HTMLElement = null) {
    if (typeof element === "string") {
      this.create(element);
    } else {
      this.element = element || null;
    }
  }

  create(type: string = "div"): DOMHelp {
    this.element = document.createElement(type);
    return this;
  }

  addClass(className: string): DOMHelp {
    if (className) {
      this.element.classList.add(className);
    }
    return this;
  }

  removeClass(className: string): DOMHelp {
    this.element.classList.remove(className);
    return this;
  }

  hasClass(className: string = ""): boolean {
    return this.element.classList.contains(className);
  }

  attr(attr: string, value: string = ""): DOMHelp {
    this.element.setAttribute(attr, value);
    return this;
  }

  /**
   * Set style to current element
   * @param {string|CSSStyleDeclaration} attr
   * @param {string} value
   * @returns {DOMHelp}
   */

  style(attr: string, value: string = ""): DOMHelp {
    this.element.style[attr] = value;
    return this;
  }

  text(text: string = ""): DOMHelp {
    this.element.innerText = text;
    return this;
  }

  html(html: string | HTMLElement): DOMHelp {
    this.element.innerHTML = html;
    return this;
  }

  on(event: string | HTMLElementEventMap, func: EventListenerOrEventListenerObject, capture: boolean = false): DOMHelp {
    this.element.addEventListener(event, func, capture);
    return this;
  }

  hide(): DOMHelp {
    this.style("display", "none");
    return this;
  }

  show(): DOMHelp {
    this.style("display", "block");
    return this;
  }

  isVisible(): boolean {
    return !(this.element.offsetWidth === 0 && this.element.offsetHeight === 0);
  }

  remove(): boolean {
    try {
      this.element.parentNode.removeChild(this.element);
      return true;
    } catch (e) {
      return false;
    }
  }

  add(element: DOMHelp | DOMHelp[]): DOMHelp {
    if (element === null) {
      return this;
    }

    if ((<DOMHelp>element).get) {
      this.element.appendChild((<DOMHelp>element).get());
    } else {
      this.addArray(<DOMHelp[]>element);
    }
    return this;
  }

  addNative(element: HTMLElement): DOMHelp {
    this.element.appendChild(element);
    return this;
  }

  addList(elements: DOMHelp[], className: string): DOMHelp {
    const items = elements.map(item => (item ? new DOMHelp("li").add(item) : null));
    this.add(new DOMHelp("ul").addArray(items).addClass(className));
    return this;
  }

  addArray(elements: DOMHelp[]): DOMHelp {
    elements.map(item => {
      if (item) {
        this.add(item);
      }
    });
    return this;
  }

  clearChilds(): DOMHelp {
    while (this.element.firstChild) {
      this.element.removeChild(this.element.firstChild);
    }
    return this;
  }

  getHtml(): HTMLElement {
    return this.element.innerHTML;
  }

  get(): HTMLElement {
    return this.element;
  }
}
