import DOMHelp from "./utils/html";
import { CopyToClipboard } from "./utils/copyClipboard";

export default class ContextMenu {
  public contextWrapper: any;
  public contextInnerWrapper: any;
  public contextLinksWrapper: any;
  public copyVideoUrlButton: any;
  public copyVideoUrlCurrentButton: any;
  public copyEmbedButton: any;
  public keyboardHelpButton: any;
  public versionWrapper: any;
  public versionText: any;
  public keyboardHelpWrapper: any;
  public keyboardHelpHeader: any;
  public keyboardHelpHeaderText: any;
  public keyboardHelpCloseWrapper: any;
  public keyCodeListWrapper: any;
  public cursor_offset_top: any;
  public cursor_offset_bottom: any;
  public cursor_offset_left: any;
  public cursor_offset_right: any;
  public isActive: boolean = false;

  constructor(private player: any) {
    this.render();
  }

  render(): void {
    this.renderContextMenu();
    this.renderKeyboardHelp();
    this.setupEvents();
  }

  renderContextMenu(): void {
    this.contextWrapper = new DOMHelp("div").addClass("context-menu-wrapper").hide();
    this.contextInnerWrapper = new DOMHelp("div").addClass("context-inner-wrapper");
    this.contextLinksWrapper = new DOMHelp("div").addClass("context-links-wrapper");

    this.copyVideoUrlButton = new DOMHelp("button").text(this.player.lang.get("copyVideoUrl"));
    this.copyVideoUrlCurrentButton = new DOMHelp("button").text(this.player.lang.get("copyVideoUrlCurrentTime"));
    this.copyEmbedButton = new DOMHelp("button").text(this.player.lang.get("copyEmbedCode"));
    this.keyboardHelpButton = new DOMHelp("button").text(this.player.lang.get("keyboardHelp"));

    if (!this.player.options.copyEmbedUrl) {
      this.copyEmbedButton = null;
    }

    this.contextLinksWrapper.addList([
      this.copyVideoUrlButton,
      this.copyVideoUrlCurrentButton,
      this.copyEmbedButton,
      this.keyboardHelpButton
    ]);

    this.versionWrapper = new DOMHelp("div").addClass("player-version-wrapper");
    this.versionText = new DOMHelp("p").text(`${this.player.lang.get("playerVersion")}: ${this.player.version}`);
    this.versionWrapper.add(this.versionText);

    this.contextInnerWrapper.addArray([this.contextLinksWrapper, this.versionWrapper]);
    this.contextWrapper.add(this.contextInnerWrapper);
    this.player.container.add(this.contextWrapper);
  }

  renderKeyboardHelp(): void {
    this.keyboardHelpWrapper = new DOMHelp("div").addClass("keyhelp-wrapper").hide();

    this.keyboardHelpHeader = new DOMHelp("div").addClass("keyhelp-header-wrapper");
    this.keyboardHelpHeaderText = new DOMHelp("p").text(`${this.player.lang.get("keyHelpHeader")}`);
    this.keyboardHelpCloseWrapper = new DOMHelp("div").addClass("keyhelp-close");
    this.keyboardHelpCloseWrapper.addArray([new DOMHelp("span"), new DOMHelp("span")]);

    this.keyboardHelpHeader.addArray([this.keyboardHelpHeaderText, this.keyboardHelpCloseWrapper]);

    this.keyCodeListWrapper = new DOMHelp("div").addClass("keyhelp-inner-wrapper");

    this.keyCodeListWrapper
      .addList([
        this.renderKey(`SPACE`, "keyHelpSpace"),
        this.renderKey(`F`, "keyHelpFKey"),
        this.renderKey(`M`, "keyHelpMKey"),
        this.renderKey(`↑`, "keyHelpUpKey"),
        this.renderKey(`↓`, "keyHelpDownKey")
      ])
      .addList([
        this.renderKey(`←`, "keyHelpLeftKey"),
        this.renderKey(`→`, "keyHelpRightKey"),
        this.renderKey(`Page Up`, "keyHelpPUpKey"),
        this.renderKey(`Page Down`, "keyHelpPDownKey")
      ]);

    this.keyboardHelpWrapper.addArray([this.keyboardHelpHeader, this.keyCodeListWrapper]);
    this.player.container.add(this.keyboardHelpWrapper);
  }

  renderKey(key, langKey): any {
    return [
      new DOMHelp("div").addClass("key-holder").add(new DOMHelp("p").text(key)),
      new DOMHelp("p").text(this.player.lang.get(langKey))
    ];
  }

  setupEvents(): void {
    document.addEventListener("click", () => this.closeContextMenu());
    this.contextWrapper.on("click", e => e.stopPropagation());

    this.player.containerWrapper.on("contextmenu", e => {
      if (e.target.type !== "textarea" && e.target.type !== "input" && !this.player.touchModeEnabled) {
        this.openContextMenu(e);
      } else {
        this.closeContextMenu();
      }
    });

    this.keyboardHelpButton.on("click", () => this.openKeyHelp());
    this.keyboardHelpCloseWrapper.on("click", () => this.closeKeyHelp());

    this.copyVideoUrlButton.on("click", () => {
      CopyToClipboard(this.player.options.copyUrl || location.href);
      this.closeContextMenu();
    });

    this.copyVideoUrlCurrentButton.on("click", () => {
      CopyToClipboard(this.getCurrentCopyLink());
      this.closeContextMenu();
    });

    if (this.copyEmbedButton) {
      this.copyEmbedButton.on("click", () => {
        CopyToClipboard(this.player.options.copyEmbedUrl);
        this.closeContextMenu();
      });
    }
  }

  closeContextMenu(): void {
    this.contextWrapper.hide();
  }

  getCurrentCopyLink(): any {
    let query = `t=${parseInt(this.player.video.currentTime)}`;

    if (typeof URLSearchParams === "function") {
      let searchParams = new URLSearchParams(location.search);
      searchParams.set("t", parseInt(this.player.video.currentTime).toString());
      query = searchParams.toString();
    }

    return `${this.player.options.copyUrl || location.origin + location.pathname}?${query}`;
  }

  openContextMenu(e): void {
    // Disable context menu while Related videos layout or pre roll ad is showing
    if (this.player.videoEndedOverlay) {
      if (
        this.player.videoEndedOverlay.isRendered ||
        (this.player.vastHandler.isActive && this.player.video.currentTime > 0)
      ) {
        return;
      }
    } else {
      if (this.player.vastHandler.isActive && this.player.video.currentTime > 0) {
        return;
      }
    }

    this.isActive = true;

    this.cursor_offset_top = Math.ceil(e.clientY - this.player.container.get().getBoundingClientRect().top);
    this.cursor_offset_bottom = -(
      Math.ceil(e.clientY - this.player.container.get().getBoundingClientRect().top) -
      this.player.containerWrapper.get().offsetHeight
    );
    this.cursor_offset_left = Math.ceil(e.clientX - this.player.container.get().getBoundingClientRect().left);
    this.cursor_offset_right = -(
      Math.ceil(e.clientX - this.player.container.get().getBoundingClientRect().left) -
      this.player.containerWrapper.get().offsetWidth
    );

    this.contextWrapper.show();
    this.contextWrapper.style("top", this.cursor_offset_top + "px");
    this.contextWrapper.style("left", this.cursor_offset_left + "px");

    if (this.cursor_offset_left < this.cursor_offset_right) {
      this.contextInnerWrapper.style("left", "0");
      this.contextInnerWrapper.style("right", null);
    } else {
      this.contextInnerWrapper.style("left", null);
      this.contextInnerWrapper.style("right", "0");
    }

    if (this.cursor_offset_top < this.cursor_offset_bottom) {
      this.contextInnerWrapper.style("top", "0");
      this.contextInnerWrapper.style("bottom", null);
    } else {
      this.contextInnerWrapper.style("top", null);
      this.contextInnerWrapper.style("bottom", "0");
    }

    e.preventDefault();
  }

  closeKeyHelp(): void {
    this.contextWrapper.hide();
    this.keyboardHelpWrapper.hide();
  }

  openKeyHelp(): void {
    this.contextWrapper.hide();
    this.keyboardHelpWrapper.show();
  }
}
