import { ErrorObject } from "../PlayerHandler/PlayerHandlerLocal";

export default class ErrorStorage {
  private readonly prefix: string;
  private readonly timestamp: number;
  public errors: object;

  constructor() {
    this.prefix = "_playerErrors";
    this.timestamp = +new Date();
    this.errors = {};
  }

  public get(): object | null {
    try {
      let previousErrors = JSON.parse(localStorage.getItem(this.prefix));

      this.errors = this.check(previousErrors);
      return this.errors;
    } catch (error) {
      console.log("[PLAYER LOG]", "LocalStorage is unavailable.");
      return null;
    }
  }

  private check(previousErrors: object): object {
    let errors = {};

    Object.keys(previousErrors).forEach(errorCode => {
      if (previousErrors[errorCode] > this.timestamp) {
        errors[errorCode] = previousErrors[errorCode];
      }
    });

    return errors;
  }

  public isReported(currentError: ErrorObject): boolean {
    const errorCodeTimestamp = this.errors[currentError.errorCode] | 0;

    return errorCodeTimestamp > this.timestamp;
  }

  public add(currentError: ErrorObject) {
    this.errors[currentError.errorCode] = this.timestamp + 3600000; // lifetime: 1 hour
  }

  public update(): void {
    try {
      localStorage.setItem(this.prefix, JSON.stringify(this.errors));
    } catch (error) {
      console.log("[PLAYER LOG]", "LocalStorage is unavailable.");
    }
  }
}
