import SettingsToggleItem from "./_settingsToggleItem";

export default class Autoplay extends SettingsToggleItem {
  constructor(public player: any) {
    super(player);

    this.localStorageKey = "html5PlayerAutoplay";
    this.config = this.player.options.autoplay;

    if (this.config.enabled) {
      this.active = this.getDefaultState();
      this.render();
    }
  }

  render(): void {
    this.button = this.getToggleBtn("autoplay-on-off");
    this.buttonWrapper = this.getLine("label-autoplay", this.player.lang.get("autoplay"), this.button);
    this.setState();
  }
}
