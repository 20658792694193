import PLAYER_STATE from "./PlayerState";
import PlayerStateProvider from "./PlayerStateProvider";

export default class PlayerHandler {
  public seeking: false;

  constructor(public playerState: PlayerStateProvider) {}

  play() {}
  pause() {}
  load() {}
  stop() {}

  getCurrentMediaTime(): number {
    return 0;
  }

  getMediaDuration(): number {
    return 100;
  }
  setTarget() {}

  updateDisplayMessage() {}

  mute() {}

  unMute() {}

  public setVolume(volume: number): void {}

  public getError(): any {
    return null;
  }

  public getErrorObject(): any {
    return null;
  }

  isMuted(): boolean {
    return false;
  }

  seekTo(time) {}

  public handlePlayPause(): void {
    if (this.playerState.state.getValue() !== PLAYER_STATE.PLAYING) {
      this.play();
    } else {
      this.pause();
    }
  }

  public loaded(): void {
    const state = this.playerState.state.getValue();
    if (state !== PLAYER_STATE.PLAYING && state !== PLAYER_STATE.PAUSED && state !== PLAYER_STATE.LOADED) {
      this.load();
      return;
    }
  }

  public setNewVideo(source: string, time: number = 0) {}
}

/*
export class PlayerHandlerOld {
  public castPlayer: any;
  public target: any;
  public seeking: any;
  public videoChange: any;

  constructor(castPlayer) {
    this.castPlayer = castPlayer;
    this.target = {};
  }

  setTarget(target) {
    this.target = target;
  }

  play() {
    if (
      this.castPlayer.playerState !== PLAYER_STATE.PLAYING &&
      this.castPlayer.playerState !== PLAYER_STATE.PAUSED &&
      this.castPlayer.playerState !== PLAYER_STATE.LOADED
    ) {
      this.load();
      return;
    }

    this.target.play();
    this.castPlayer.playerState = PLAYER_STATE.PLAYING;
    // do not fire play / pause events while seeking
    if (this.seeking) return;

    this.castPlayer.player.seeking = false;
    this.castPlayer.player.playing = true;
    // this.castPlayer.fireEvent('onPlay');
    this.castPlayer.videoChange = false;
    this.updateDisplayMessage();
  }

  pause() {
    if (this.castPlayer.playerState !== PLAYER_STATE.PLAYING) {
      return;
    }
    // do not fire play / pause events while seeking
    if (this.seeking) return;
    // do not react on paused events while changing media stream
    // the cake is a lie
    if (this.videoChange) return;

    this.castPlayer.player.playing = false;
    this.target.pause();
    // this.castPlayer.fireEvent('onPause');
    this.castPlayer.playerState = PLAYER_STATE.PAUSED;
    this.updateDisplayMessage();
  }

  stop() {
    this.target.stop();
    this.castPlayer.playerState = PLAYER_STATE.STOPPED;
    this.updateDisplayMessage();
  }

  load() {
    this.castPlayer.playerState = PLAYER_STATE.LOADING;
    this.target.load();
    this.updateDisplayMessage();
  }

  loaded() {
    // this.castPlayer.setActiveState(true);
    this.castPlayer.currentMediaDuration = this.getMediaDuration();
    this.castPlayer.playerState = PLAYER_STATE.LOADED;
    if (this.castPlayer.currentMediaTime > 0) {
      this.seekTo(this.castPlayer.currentMediaTime);
    }
    this.play();
    // this.castPlayer.startProgressTimer();
    this.updateDisplayMessage();
  }

  getCurrentMediaTime() {
    return this.target.getCurrentMediaTime();
  }

  getMediaDuration() {
    return this.target.getMediaDuration();
  }

  updateDisplayMessage() {
    this.target.updateDisplayMessage();
  }

  mute() {
    this.target.mute();
  }

  unMute() {
    this.target.unMute();
  }

  isMuted() {
    return this.target.isMuted();
  }

  seekTo(time) {
    this.target.seekTo(time);
    this.updateDisplayMessage();
  }
}
*/
