export default class Polyfils {
  constructor() {
    (function() {
      if (typeof (<any>window).CustomEvent === "function") return false;

      function CustomEvent(event, params) {
        params = params || {
          bubbles: false,
          cancelable: false,
          detail: undefined
        };
        let evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = (<any>window).Event.prototype;

      (<any>window).CustomEvent = CustomEvent;

      if (!Object.assign) {
        Object.defineProperty(Object, "assign", {
          enumerable: false,
          configurable: true,
          writable: true,
          value: function(target) {
            "use strict";

            if (target === undefined || target === null) {
              throw new TypeError("Cannot convert first argument to object");
            }

            let to = Object(target);

            for (let i = 1; i < arguments.length; i++) {
              let nextSource = arguments[i];

              if (nextSource === undefined || nextSource === null) {
                continue;
              }

              nextSource = Object(nextSource);

              let keysArray = Object.keys(Object(nextSource));

              for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                let nextKey = keysArray[nextIndex];
                let desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);

                if (desc !== undefined && desc.enumerable) {
                  to[nextKey] = nextSource[nextKey];
                }
              }
            }

            return to;
          }
        });
      }
    })();
  }
}
