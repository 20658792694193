import {TextAdInterface} from "./_config";
import DOMHelp from "./utils/html";
import {IconOutLink} from "./utils/icons";
import PLAYER_STATE from "./PlayerHandler/PlayerState";

export default class TextAd {
	private readonly config: TextAdInterface;
	public isActive: boolean;
	public timer: number;
	public textLink: any;
	public textLinkWrapper: any;

	constructor(private player: any) {
		this.config = this.player.options.textAd;
		this.isActive = false;
		this.timer = 0;

		this.init();
	}

	init() {
		// Check config
		if (!this.isEnabled(this.config) || (!this.player.isMobile && this.player.options.videoAd.enabled)) return;

		// Update config
		this.config.displayAfter = this.config.displayAfter < 0 ? 0 : this.config.displayAfter;

		this.composeTextAd();

		const playEvent = this.player.playerState.state.subscribe(state => {
			if (state === PLAYER_STATE.PLAYING) {
				this.show();
				playEvent.unsubscribe();
			}
		});
	}

	isEnabled(config: TextAdInterface) {
		return config.enabled && config.text && typeof config.text === "string" && config.url && typeof config.url === "string" && typeof config.displayAfter === "number" && typeof config.hideAfter === "number";
	}

	composeTextAd() {
		this.textLink = new DOMHelp("a")
			.attr("href", this.config.url)
			.attr("target", "_blank")
			.addClass("text-link")
			.html(this.config.text + IconOutLink.getHtml());

		this.textLinkWrapper = new DOMHelp("div").addClass("text-link-wrapper").add(this.textLink).hide();
		this.player.container.add(this.textLinkWrapper);
	}

	show(): void {
		this.isActive = this.config.hideAfter !== 0;
		this.textLinkWrapper.show();
	}

	hide(): void {
		this.isActive = false;
		this.textLinkWrapper.hide();
	}

	updateTimer(): void {
		this.timer++;

		if (this.timer >= this.config.hideAfter) {
			this.hide();
		}
	}
}