export default class HttpModule {
  constructor() {}

  get(url: string): Promise<any> {
    /**
     * Usage:
     * .get('http://domain.com/');
     */

    return new Promise((resolve: any, reject: any) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };

      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.send();
    });
  }

  post(props: PostConfig): Promise<any> {
    /**
     * .post({
     *    url: 'http://domain.com/',
     *    params: {
     *      score: 1
     *    },
     *    headers: {
     *      'Content-type': 'application/x-www-form-urlencoded'
     *    }
     * });
     */

    return new Promise((resolve: any, reject: any) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", props.url);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };

      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      if (props.headers) {
        Object.keys(props.headers).forEach(key => {
          xhr.setRequestHeader(key, props.headers[key]);
        });
      }

      let params: PostHeadersObject = props.params;
      let encodedParams: string;

      if (params && typeof params === "object") {
        encodedParams = Object.keys(params)
          .map(key => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
          })
          .join("&");
      }

      xhr.send(encodedParams);
    });
  }
}

interface PostConfig {
  url: string;
  params: PostParamsObject;
  headers: PostHeadersObject;
}

interface PostParamsObject {
  [propName: string]: any;
}

interface PostHeadersObject {
  [propName: string]: string;
}
