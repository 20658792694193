export default class VideoStats {
  public timingArray: number[];
  public browsingData: number[];
  private videoStatsPayload: VideoStatsPayloadInterface = {
    url: window.location.href,
    percentWatched: 0,
    browsingData: []
  };

  constructor(private player: any) {
    this.timingArray = [];
    this.browsingData = [];

    // Bindings
    this.visibilityChangeHandler = this.visibilityChangeHandler.bind(this);

    // Initialization
    if (this.player.options.videoStats.enabled) {
      this.init();
    }
  }

  init(): void {
    window.addEventListener("visibilitychange", this.visibilityChangeHandler);
  }

  visibilityChangeHandler() {
    if (this.player.isSourceVideoShown && document.visibilityState === "hidden") {
      // const watchedDuration = this.timingArray.length;

      // Case more than 100%
      const watchedDuration = this.browsingData.length;

      if (watchedDuration > 0) {
        this.videoStatsPayload.percentWatched = Math.floor((watchedDuration * 100) / this.player.video.duration);
        this.videoStatsPayload.browsingData = this.updateBrowsingData(this.browsingData);
        navigator.sendBeacon(this.player.options.videoStats.apiURL, JSON.stringify(this.videoStatsPayload));

        // Update data array
        this.timingArray = [];
        this.browsingData = [];
      }
    }
  }

  updateBrowsingData(browsingData) {
    let uniqueData = [];
    let lastValue;

    browsingData.forEach(value => {
      if (value !== lastValue) {
        uniqueData.push(value);
        lastValue = value;
      }
    });

    let data = [];
    let set = [];

    uniqueData.forEach((value, index) => {
      if (set.length === 0) {
        set[0] = value;
        set[1] = value;
      }

      if (uniqueData[index + 1] && uniqueData[index + 1] - value === 1) {
        set[1] = uniqueData[index + 1];
      } else {
        data.push(set);
        set = [];
      }
    });

    return data;
  }
}

interface VideoStatsPayloadInterface {
  url: string;
  percentWatched: number;
  browsingData: number[];
}
