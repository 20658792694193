export default {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
  STOPPED: "STOPPED",
  ENDED: "ENDED",
  ERROR: "ERROR"
};
