// import DOMHelp from "./utils/html";
import * as KeyCode from "keycode-js";
import DOMHelp from "./utils/html";
import { IconPlay, IconVolumeDown, IconVolumeUp, IconBack, IconNext } from "./utils/icons";

export default class Keybinds {
  public overlayWrapper: any;
  public iconWrapper: any;
  public icon: any;
  public descriptionWrapper: any;
  public descriptionSpan: any;
  public description: string;
  public iconIsShowing: boolean;

  constructor(private player: any) {
    this.iconIsShowing = false;
    this.description = "";
    this.player = player;
    this.setupKeyEvents();
    this.renderIconOverlay();
  }

  renderIconOverlay(): void {
    this.overlayWrapper = new DOMHelp()
      .create("div")
      .addClass("video-play-overlay")
      .hide();
    this.iconWrapper = new DOMHelp().create("div").addClass("overlay-play-button-wrapper");
    this.icon = new DOMHelp()
      .create("div")
      .addClass("overlay-play-button")
      .addClass("video-play-overlay-icon");
    this.descriptionWrapper = new DOMHelp()
      .create("div")
      .addClass("overlay-icon-description")
      .hide();
    this.descriptionSpan = new DOMHelp().create("span");

    this.iconWrapper.add(this.icon);
    this.descriptionSpan.text(this.description);
    this.descriptionWrapper.add(this.descriptionSpan);
    this.overlayWrapper.add(this.iconWrapper);
    this.overlayWrapper.add(this.descriptionWrapper);
    this.player.container.add(this.overlayWrapper);
  }

  setIcon(key: string): void {
    switch (key) {
      case "down":
        this.icon.html(IconVolumeDown.getHtml());
        break;
      case "up":
        this.icon.html(IconVolumeUp.getHtml());
        break;
      case "left":
        this.icon.html(IconBack.getHtml());
        break;
      case "right":
        this.icon.html(IconNext.getHtml());
        break;
      default:
        this.icon.html("");
    }
  }

  showIcon(): void {
    if (this.description === "") {
      this.descriptionWrapper.hide();
    } else {
      this.descriptionWrapper.show();
    }

    if (!this.iconIsShowing) {
      this.iconIsShowing = true;
      this.overlayWrapper.show();
      this.overlayWrapper.style("opacity", "1");
      this.overlayWrapper.style("transform", "scale(1.0)");
      setTimeout(() => {
        this.hideIcon();
      }, 400);
    }
  }

  hideIcon(): void {
    this.overlayWrapper.style("opacity", "0");
    this.overlayWrapper.style("transform", "scale(1.2)");
    setTimeout(() => {
      this.overlayWrapper.hide();
      this.overlayWrapper.style("transform", "scale(1.0)");
      this.iconIsShowing = false;
      setTimeout(() => {
        this.overlayWrapper.style("transform", "scale(1.2)");
      }, 200);
    }, 200);
  }

  setupKeyEvents(): void {
    let focused = false;
    let mouseOverPlayer = false;

    this.player.containerWrapper.on("mouseover", e => {
      if (!mouseOverPlayer) {
        mouseOverPlayer = true;
        //console.log(`mouseOverPlayer: ${mouseOverPlayer}`);
      }
    });

    this.player.containerWrapper.on("mouseleave", e => {
      if (mouseOverPlayer) {
        mouseOverPlayer = false;
        //console.log(`mouseOverPlayer: ${mouseOverPlayer}`);
      }
    });

    document.body.addEventListener("click", e => {
      focused = mouseOverPlayer;
    });

    // key pressing event listener
    document.activeElement.addEventListener("keydown", e => {
      if (focused) {
        this.handleKeySwitch(e);

        //console.log(`key pressed on ${e.target.nodeName} with class (${e.target.className})`);
      }
    });

    // Tab Focus Handler
    /*document.body.addEventListener("keyup", e => {

            console.log(`KeyUp target: ${e.target.nodeName} + ${e.target.className}`);

            if(e.keyCode == KeyCode.KEY_TAB && this.player.containerWrapper.get().focus().length) {
                console.log(`Focused by TAB key`)
            }
        });*/
  }

  handleKeySwitch(e): void {
    switch (e.keyCode) {
      case KeyCode.KEY_SPACE:
        // TEST LOG KeyPushed - SPACE
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_SPACE} - SPACE`);
        e.preventDefault();

        // Show control bar
        this.player.showControlsBar(4000);

        // Toggle Play / Pause on SPACE key
        this.player.handlePlayPauseOnKey();

        break;

      case KeyCode.KEY_F:
        //TEST LOG Key Pushed - F
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_F} - F`);
        e.preventDefault();

        // Show control bar
        this.player.showControlsBar(4000);

        // Fullscreen toggle handle same as on click
        this.player.fullscreen.handleFullscreen();

        // TEST LOG Fullscreen State switch
        if (this.player.fullscreen.isFullScreen()) {
          //this.handleKeyMessages("stateChange", "Fullscreen OFF");
        } else {
          //this.handleKeyMessages("stateChange", "Fullscreen ON");
        }

        break;

      case KeyCode.KEY_C:
        //TEST LOG Key Pushed - C
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_C} - C`);

        /*this.player.log(`Future feature ON / OFF Video thumbnails.`);
                e.preventDefault();*/

        break;

      case KeyCode.KEY_W:
        //TEST LOG Key Pushed - W
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_W} - W`);

        break;

      case KeyCode.KEY_M:
        //TEST LOG Key Pushed - M
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_M} - M`);
        e.preventDefault();

        // Show control bar
        this.player.showControlsBar(4000);

        this.player.volume.setMuteByKey();

        break;

      case KeyCode.KEY_UP:
        //TEST LOG Key Pushed - UP
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_UP} - UP`);
        e.preventDefault();

        // if (this.player.video.volume >= 1) {
        //   return;
        // }

        // Show control bar
        this.player.showControlsBar(4000);

        this.player.volume.volumeIncrease();
        this.description = Math.round(this.player.video.volume * 100) + "%";
        this.descriptionSpan.text(this.description);

        // Set and display icon
        this.setIcon("up");
        this.showIcon();

        break;

      case KeyCode.KEY_DOWN:
        //TEST LOG Key Pushed - UP
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_DOWN} - DOWN`);
        e.preventDefault();

        // if (this.player.video.volume <= 0) {
        //   return;
        // }

        // Show control bar
        this.player.showControlsBar(4000);
        this.player.volume.volumeDecrease();
        this.description = Math.round(this.player.video.volume * 100) + "%";
        this.descriptionSpan.text(this.description);

        // Set and display icon
        this.setIcon("down");
        this.showIcon();

        break;

      case KeyCode.KEY_LEFT:
        //TEST LOG Key Pushed - LEFT
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_LEFT} - LEFT`);
        e.preventDefault();

        // if (this.player.video.currentTime === 0) {
        //   return;
        // }

        // Show control bar
        this.player.showControlsBar(4000);

        this.player.progressBar.videoTimeBackwards();
        this.description = "";
        this.descriptionSpan.text(this.description);

        // Set and display icon
        this.setIcon("left");
        this.showIcon();

        break;

      case KeyCode.KEY_RIGHT:
        //TEST LOG Key Pushed - RIGHT
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_RIGHT} - RIGHT`);
        e.preventDefault();

        // if (this.player.video.currentTime >= this.player.video.duration) {
        //   return;
        // }

        // Show control bar
        this.player.showControlsBar(4000);

        this.player.progressBar.videoTimeForwards();
        this.description = "";
        this.descriptionSpan.text(this.description);

        // Set and display icon
        this.setIcon("right");
        this.showIcon();

        break;

      case KeyCode.KEY_PAGE_UP:
        //TEST LOG Key Pushed - PAGE_UP
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_PAGE_UP} - PAGE_UP`);
        e.preventDefault();

        // Show control bar
        this.player.showControlsBar(4000);

        this.player.playbackRate.videoSpeedUp();

        break;

      case KeyCode.KEY_PAGE_DOWN:
        //TEST LOG Key Pushed - PAGE_DOWN
        //this.handleKeyMessages("rightKeyPushed", `${KeyCode.KEY_PAGE_DOWN} - PAGE_DOWN`);
        e.preventDefault();

        // Show control bar
        this.player.showControlsBar(4000);

        this.player.playbackRate.videoSpeedDown();

        break;

      default:
        // TEST LOG KeyPushed not used key
        //this.handleKeyMessages("notDefinedKey");
        break;
    }
  }

  /*
    * KeyBind Error Handler
    *
    * Mostly testing states for different situation that can occure. Handling keypushing, not defined keys and no change in state on key push.
    *
    */

  handleKeyMessages(errorType, attribute = null): void {
    switch (errorType) {
      case "rightKeyPushed":
        this.player.log(`Key Pushed: ${attribute}`);
        break;

      case "notDefinedKey":
        this.player.log(`Not useful key pressed.`);
        break;

      case "isMaxed":
        this.player.log(`Already maxed attribute: ${attribute}`);
        break;

      case "isMinimized":
        this.player.log(`Already minimized attribute: ${attribute}`);
        break;

      case "stateChange":
        this.player.log(`New state: ${attribute}`);
        break;

      case "stateAlready":
        this.player.log(`Already in state: ${attribute}`);
        break;

      default:
        this.player.log(`You broke it. Unknown KeyHandler Error.`);
        break;
    }
  }
}
