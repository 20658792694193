import DOMHelp from "./utils/html";
import { IconSpeakerFull, IconSpeakerMute } from "./utils/icons";

let volumeChangeAmount = 0.05;
const localStorageKeyMuted = "html5PlayerVolumeMuted";
const localStorageKeyVolume = "html5PlayerVolume";

export default class Volume {
  private volumeDrag: boolean = false;

  public wrapper: any;
  public mute: any;
  public btnWrapper: any;
  public volumeActual: any;
  public volume: any;

  constructor(private player: any) {
    this.render();
    this.setupEvents();
  }

  render(): void {
    this.wrapper = new DOMHelp().create("div").addClass("video-volume");

    this.mute = new DOMHelp()
      .create("button")
      .addClass("video-volume-mute")
      .addClass("muted")
      .html(IconSpeakerFull.getHtml());

    this.btnWrapper = new DOMHelp()
      .create("div")
      .addClass("mute-button-wrapper")
      .addClass("tooltip-button");
    this.btnWrapper.add(this.mute);

    this.volumeActual = new DOMHelp().create("div").addClass("volume-range-actual");

    this.volume = new DOMHelp()
      .create("div")
      .addClass("volume-range-progress")
      .add(this.volumeActual);

    const rangeWrapper = new DOMHelp()
      .create("div")
      .addClass("volume-range")
      .add(this.volume);

    this.wrapper.add(this.btnWrapper).add(rangeWrapper);
    this.setupDefaultState();
  }

  public setupDefaultState(): void {
    this.setMute(this.player.storage.load(localStorageKeyMuted, false, null));
    const volume = this.player.storage.load(localStorageKeyVolume, 1, null);
    this.player.video.muted ? this.uiRenderVolume(volume) : this.setVolume(volume);
  }

  getVolumeBar(): any {
    return this.wrapper;
  }

  setupEvents(): void {
    this.btnWrapper.on("click", () => this.setMute(!this.player.playerState.isMuted.getValue()));
    this.player.video.addEventListener("volumechange", () => this.volumeChanged());

    this.volume.on("mousedown", e => {
      this.volumeDrag = true;
      this.player.container.addClass("setting-volume");
      this.updateVolumeBar(e);
    });

    document.addEventListener("mouseup", e => {
      if (this.volumeDrag) {
        this.volumeDrag = false;
        setTimeout(() => this.player.container.removeClass("setting-volume"), 300);
        this.updateVolumeBar(e);
      }
    });

    document.addEventListener("mousemove", e => {
      if (this.volumeDrag) {
        this.updateVolumeBar(e);
      }
    });

    this.player.playerState.isMuted.subscribe(isMuted => this.uiRenderMute(isMuted));
    this.player.playerState.volume.subscribe(isMuted => this.uiRenderVolume(isMuted));
  }

  setMuteByKey(): void {
    // Helper function to react on keydown event for mute / unmute video on keypush

    if (!this.player.video.muted) {
      this.setMute(true);
    } else {
      this.setVolume(this.player.video.volume);
      this.uiRenderVolume(this.player.video.volume);
      this.setMute(false);
    }
  }

  setMute(muted): void {
    muted ? this.player.playerHandler.mute() : this.player.playerHandler.unMute();
    if (!this.player.playerState.isCasting.getValue()) {
      this.player.storage.save(localStorageKeyMuted, muted);
    }
    this.player.playerState.isMuted.next(muted);
  }

  volumeChanged(): void {
    if (this.player.video.muted) {
      this.uiRenderVolume(0);
    } else {
      this.setVolume(this.player.video.volume);
      this.uiRenderVolume(this.player.video.volume);
      this.setMute(this.player.video.volume === 0);
    }
  }

  setVolume(value): void {
    this.player.playerHandler.setVolume(value);
    this.player.playerState.volume && this.setMute(false);
    this.player.playerState.isCasting.getValue() || this.player.storage.save(localStorageKeyVolume, value);
  }

  uiRenderMute(isMuted): void {
    this.mute.html(isMuted ? IconSpeakerMute.getHtml() : IconSpeakerFull.getHtml());
    this.btnWrapper.attr("data-tooltip", this.player.lang.get(isMuted ? "unmute" : "mute"));
  }

  uiRenderVolume(value): void {
    this.volumeActual.style("width", `${value * 100}%`);
  }

  updateVolumeBar(e): void {
    let left = Math.ceil(e.clientX - this.volume.get().getBoundingClientRect().left);
    if (left < 0) {
      left = 0;
    }
    if (left > this.volume.get().offsetWidth) {
      left = this.volume.get().offsetWidth;
    }

    const value = left / this.volume.get().offsetWidth;
    this.setVolume(value);
    this.uiRenderVolume(value);
  }

  volumeIncrease(): void {
    //console.log(this.player.video.volume);

    if (this.player.video.volume === 1 && !this.player.video.muted) {
      //console.log(`Volume maxed.`);
    } else {
      if (this.player.video.muted) {
        this.setVolume(volumeChangeAmount);
        this.uiRenderVolume(volumeChangeAmount);
        this.setMute(false);
        //console.log(`Video unmuted, New Volume: ${volumeChangeAmount}`);
      } else if (this.player.video.volume + volumeChangeAmount >= 1) {
        this.setVolume(1);
        this.uiRenderVolume(1);

        //console.log(`Volume maxed.`);
      } else {
        let volumeIncreased = this.player.video.volume + volumeChangeAmount;

        this.setVolume(volumeIncreased);
        this.uiRenderVolume(volumeIncreased);

        //console.log(`New Video volume: ${volumeIncreased}`);
      }
    }
  }

  volumeDecrease(): void {
    if (this.player.video.muted) {
      //console.log(`Video already muted`);
    } else {
      if (this.player.video.volume - volumeChangeAmount <= 0) {
        this.setVolume(0);
        this.uiRenderVolume(0);
        this.setMute(true);

        //console.log(`Volume minimized and muted.`);
      } else {
        let volumeDecrease = this.player.video.volume - volumeChangeAmount;
        this.setVolume(volumeDecrease);
        this.uiRenderVolume(volumeDecrease);

        //console.log(`New Video volume: ${volumeDecrease}`);
      }
    }
  }
}
