export default class StorageProvider {
  private localStorageAvailable: boolean;

  constructor() {
    this.localStorageAvailable = typeof Storage !== "undefined";
  }

  public save(key: string, value: any, remoteSave?: (active) => void): void {
    if (this.localStorageAvailable) {
      (localStorage as Storage).setItem(key, value);
    }
    if (remoteSave) {
      remoteSave(value);
    }
  }

  public load(key: string, defaultValue: any, loadFromServer?: () => void): any {
    if (loadFromServer) {
      this.save(key, defaultValue);
      return defaultValue;
    }

    if (this.localStorageAvailable && !loadFromServer) {
      const value = localStorage.getItem(key);
      return value === null ? defaultValue : this._parseValue(defaultValue, value);
    } else {
      return defaultValue;
    }
  }

  private _parseValue(defaultValue: any, loadedValue: any): any {
    try {
      if (typeof defaultValue === "number") {
        return JSON.parse(loadedValue);
      } else if (typeof defaultValue === "boolean") {
        return JSON.parse(loadedValue) === true;
      } else {
        return loadedValue;
      }
    } catch (e) {
      return defaultValue;
    }
  }
}
