export default class VTTThumbnails {
	public enabled: boolean;
	public vttThumbnails: any[];
	public vttSpriteSize: any;
	public vttThumbnailSize: any;
	public isHD: boolean;
	public vttSpriteURL: string;
	public vttSpriteImages: any[];

	constructor(public player: any) {
		this.enabled = true;
		this.isHD = false;
	}

	init() {
		return new Promise((resolve, reject) => {
			if (!this.player.options.vttThumbnails.enabled) {
				this.enabled = false;
				reject();
			}

			const vttFileURL = this.getVTTFileURL();

			if (!vttFileURL) {
				this.enabled = false;
				reject();
			}

			this.player.http.get(vttFileURL)
				.then(response => {
					this.vttThumbnails = this.getVTTThumbnails(response);

					if (this.vttThumbnails.length === 0) {
						this.enabled = false;
						reject();
					}

					this.vttThumbnailSize = this.getVTTThumbnailSize(this.vttThumbnails);
					this.vttSpriteURL = this.vttThumbnails[0] ? this.vttThumbnails[0]['thumbnailURL'] : '';
					this.vttSpriteImages = this.getVTTSpriteImages(this.vttThumbnails);

					resolve();
				})
				.catch(errorObject => {
					this.enabled = false;
					this.player.log(`VTT > ${errorObject.statusText}`);
					reject();
				});
		})
	}

	getScreenResolution(): object {
		const realWidth: number = window.screen.width * window.devicePixelRatio;
		const realHeight: number = window.screen.height * window.devicePixelRatio;
		return {
			width: realWidth,
			height: realHeight
		};
	}

	getVTTFileURL(): string {
		const devicePixelRatio = window.devicePixelRatio;
		const deviceResolution = this.getScreenResolution();
		const isHDResolution: boolean = (devicePixelRatio >= 2 || Math.max(deviceResolution['width'], deviceResolution['height']) > 4000);
		this.isHD = isHDResolution && this.player.options.vttThumbnails.urlHD;

		return this.isHD ? this.player.options.vttThumbnails.urlHD : this.player.options.vttThumbnails.url;
	}

	getVTTThumbnails(response: string): any {
		const vttTimeline = response.split('\n\n').filter(item => item && item !== 'WEBVTT');

		return vttTimeline.map(vttBlock => {
			const vttParams = vttBlock.split('\n');
			return {
				id: vttParams[0],
				start: vttParams[1].split(' --> ')[0].split('.')[0].split(':').reverse().reduce((acc, currentValue, index) => {return acc + +currentValue * Math.pow(60, index)}, 0),
				end: vttParams[1].split(' --> ')[1].split('.')[0].split(':').reverse().reduce((acc, currentValue, index) => {return acc + +currentValue * Math.pow(60, index)}, 0),
				thumbnailURL: vttParams[2].split('#xywh=')[0],
				position: {
					x: vttParams[2].split('#xywh=')[1].split(',')[0],
					y: vttParams[2].split('#xywh=')[1].split(',')[1]
				},
				size: {
					width: vttParams[2].split('#xywh=')[1].split(',')[2],
					height: vttParams[2].split('#xywh=')[1].split(',')[3]
				}
			}
		});
	}

	getThumbnailData(time: number):any  {
		const thumbnails = this.vttThumbnails.filter(thumbnail => {
			return time >= thumbnail['start'] && time <= thumbnail['end'];
		});

		if (thumbnails.length === 0) {
			return null;
		} else {
			return thumbnails[thumbnails.length - 1];
		}
	}

	getVTTSpriteSize(thumbnailURL) {
		const thumbnailData = this.vttThumbnails.filter(data => data['thumbnailURL'] === thumbnailURL);
		const maxX: number = Math.max(...thumbnailData.map(thumbnail => thumbnail['position']['x']));
		const maxY: number = Math.max(...thumbnailData.map(thumbnail => thumbnail['position']['y']));
		const thumbnailWidth: number = +thumbnailData[0]['size']['width'];
		const thumbnailHeight: number = +thumbnailData[0]['size']['height'];

		return this.isHD ? {width: (maxX + thumbnailWidth) / 2, height: (maxY + thumbnailHeight) / 2} : {width: maxX + thumbnailWidth, height: maxY + thumbnailHeight};
	}

	getVTTThumbnailSize(vttThumbnails) {
		return this.isHD ? {width: vttThumbnails[0].size.width / 2, height: vttThumbnails[0].size.height / 2} : {width: vttThumbnails[0].size.width, height: vttThumbnails[0].size.height};
	}

	getVTTSpriteImages(vttThumbnails): any {
		const thumbnailURLs = vttThumbnails.map(vttThumbnail => vttThumbnail['thumbnailURL']).filter(this.unique);
		const vttSpriteImages = {};

		thumbnailURLs.forEach(thumbnailURL => vttSpriteImages[thumbnailURL] = this.getVTTSpriteSize(thumbnailURL))

		return vttSpriteImages;
	}

	unique(value, index, array) {
		return array.indexOf(value) === index;
	}

	getThumbnail(): any {
		// const thumbnailData = this.getThumbnailData() || null;

		// if (!thumbnailData) {
		// 	return;
		// }

		// const thumbnail = this.composeThumbnail();
	}

	composeThumbnail() {

	}
}