import { BehaviorSubject } from "rxjs";
import PLAYER_STATE from "./PlayerState";

export default class PlayerStateProvider {
  public state: BehaviorSubject<string> = new BehaviorSubject(PLAYER_STATE.IDLE);
  public currentTime: BehaviorSubject<number> = new BehaviorSubject(0);
  public isCasting: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isAdvertise: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMuted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isWidescreen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isFullscreen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public volume: BehaviorSubject<number> = new BehaviorSubject(1);
}
