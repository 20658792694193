import DOMHelp from "./utils/html";
import VTTThumbnails from "./VTTThumbnails";

export default class ProgressBar {
  public video: any;
  public wrapper: any;
  public thumbnailWrapper: any;
  public thumbnail: any;
  public mouseTime: any;
  public progressWrapper: any;
  public buffer: any;
  public mouseBar: any;
  public progress: any;
  public timerWrapper: any;
  public currentTime: any;
  public totalTime: any;
  public vttThumbnails: any;
  public isVTTEnabled: boolean;

  private baseThumb: string;
  private thumbsCount: number;
  private currentThumb: number;
  private debounce: number;
  private timeChangeModifier: number;
  private timeDrag: boolean;
  private thumbnailElement: HTMLElement;

  constructor(private player: any) {
    this.player = player;
    this.video = this.player.video;
    this.baseThumb = this.player.options.thumbBase;
    this.thumbsCount = this.player.options.thumbsCount;
    this.currentThumb = -1;
    this.debounce = 0;
    this.timeDrag = false;
    this.timeChangeModifier = 5;
    this.isVTTEnabled = false;

    // Init modules
    this.vttThumbnails = new VTTThumbnails(this.player);

    this.render();
    this.setupEvents();
  }

  render(): void {
    this.wrapper = new DOMHelp().create("div").addClass("video-progress");
    this.thumbnailWrapper = new DOMHelp().create("div").addClass("video-thumbnail-preview-wrapper");
    this.thumbnail = new DOMHelp().create("img").addClass("video-thumbnail-preview");
    this.mouseTime = new DOMHelp().create("span").addClass("video-thumbnail-preview-time");
    this.thumbnailWrapper
      .add(this.thumbnail)
      .add(this.mouseTime);

    this.thumbnailWrapper.hide();

    this.progressWrapper = new DOMHelp().create("div").addClass("video-progress-bar");
    this.buffer = new DOMHelp().create("span").addClass("video-progress-bar-buffer");
    this.mouseBar = new DOMHelp().create("span").addClass("video-progress-bar-mouse");
    this.progress = new DOMHelp().create("span").addClass("video-progress-bar-progress");
    this.progressWrapper
      .add(this.buffer)
      .add(this.mouseBar)
      .add(this.progress);

    this.wrapper.add(this.thumbnailWrapper).add(this.progressWrapper);

    this.vttThumbnails.init()
      .then(() => {
        this.thumbnailWrapper
          .style("width", `${this.vttThumbnails.vttThumbnailSize['width']}px`)
          .style("height", `${this.vttThumbnails.vttThumbnailSize['height']}px`);

        this.thumbnail
          .attr('src', `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="${this.vttThumbnails.vttThumbnailSize['width']}" height="${this.vttThumbnails.vttThumbnailSize['height']}"/>`)
          .style('background', `url(${this.vttThumbnails.vttSpriteURL}) 0 0`)
          .style('background-size', `${this.vttThumbnails.vttSpriteImages[this.vttThumbnails.vttSpriteURL]['width']}px ${this.vttThumbnails.vttSpriteImages[this.vttThumbnails.vttSpriteURL]['height']}px`);

        this.isVTTEnabled = true;
        this.setupThumbnailEvents();
      })
      .catch(() => {
        this.setupThumbnailEvents();
      });

    this.timerWrapper = new DOMHelp().create("div").addClass("video-timer");
    this.currentTime = new DOMHelp().create("span").text("0:00");
    this.totalTime = new DOMHelp().create("span").text("0:00");
    this.timerWrapper
      .add(this.currentTime)
      .add(new DOMHelp().create("span").text(" / "))
      .add(this.totalTime);
  }

  getProgressBar(): any {
    return this.wrapper;
  }

  getTimer(): any {
    return this.timerWrapper;
  }

  setupEvents(): void {
    this.progressWrapper.on("mousedown", e => this.handleStartSeekEvent(e));
    this.progressWrapper.on("touchstart", e => this.handleStartSeekEvent(e.touches[0]));
    document.addEventListener("mouseup", e => this.handleEndSeekEvent(e));
    document.addEventListener("touchend", e => this.handleEndSeekEvent(e.changedTouches[0]));
    document.addEventListener("mousemove", e => this.handleSeekingEvent(e));
    document.addEventListener("touchmove", e => this.handleSeekingEvent(e.touches[0]));

    // this.video.addEventListener("timeupdate", () => this.timeUpdate());
    // this.video.addEventListener("loadedmetadata", () => this.timeUpdate());
    this.video.addEventListener("progress", () => this.buffered());

    this.player.playerState.currentTime.subscribe(time => {
      this.timeUpdate(time);
    });
  }

  setupThumbnailEvents(): void {
    this.wrapper.on("mousemove", e => this.handleMouseOver(e));
    this.wrapper.on("mouseover", e => this.handleMouseOver(e));
    this.wrapper.on("mouseout", () => (this.debounce = <any>setTimeout(this.handleMouseOut(), 100)));
    this.thumbnailWrapper.on("click", e => this.handleClick(e));
  }

  handleStartSeekEvent(e): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }
    this.timeDrag = true;
    this.player.container.addClass("video-seeking");
    this.updateTimeBar(e);
    this.video.playbackRate = 0;
  }

  handleEndSeekEvent(e): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }
    if (this.timeDrag) {
      this.timeDrag = false;
      this.player.container.removeClass("video-seeking");
      this.updateTimeBar(e);
      this.video.playbackRate = this.player.playbackRate.getCurrentSpeed();
    }
  }

  handleSeekingEvent(e): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }
    if (this.timeDrag) {
      this.updateTimeBar(e);
    }
  }

  handleClick(e): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }

    const left = Math.floor(e.clientX - this.player.container.get().getBoundingClientRect().left);
    const pos = left / this.progressWrapper.get().offsetWidth;

    if (this.player.isReduced) {
      this.player.playerHandler.seekTo(this.player.reducedVideoStartTime + pos * this.player.reducedVideoDuration);
    } else {
      this.player.playerHandler.seekTo(pos * this.video.duration);
    }
  }

  timeUpdate(currentTime: number = 0): void {
    if (!isNaN(this.video.duration)) {
      this.player.controlsBottomLeft.style("opacity", "1");

      // this.totalTime.text(this.formatTime(this.video.duration));

      if (this.player.isReduced) {
        this.totalTime.text(this.formatTime(this.player.reducedVideoDuration));
      } else {
        this.totalTime.text(this.formatTime(this.video.duration));
      }
    }

    let width: number;

    if (this.player.isReduced) {
      width =
        (this.progressWrapper.get().offsetWidth * (currentTime - this.player.reducedVideoStartTime)) /
        this.player.reducedVideoDuration;
    } else {
      width = (this.progressWrapper.get().offsetWidth * currentTime) / this.video.duration;
    }

    if (width <= 0 && this.video.currentTime !== 0) width = 1;

    this.progress.style("width", `${width.toFixed(3)}px`);

    // Collect data for video stats
    const currentSecond = Math.floor(currentTime);

    if (this.player.isSourceVideoShown && document.visibilityState === "visible" && currentSecond > 0) {
      if (this.player.videoStats.browsingData[this.player.videoStats.browsingData.length - 1] !== currentSecond) {
        this.player.videoStats.browsingData.push(currentSecond);

        // Update TextAd Module timer
        if (this.player.textAd && this.player.textAd.isActive) {
          this.player.textAd.updateTimer();
        }
      }

      if (this.player.videoStats.timingArray.indexOf(currentSecond) === -1) {
        this.player.videoStats.timingArray.push(currentSecond);
      }
    }

    if (this.player.isReduced) {
      this.currentTime.text(this.formatTime(currentTime - this.player.reducedVideoStartTime));
    } else {
      this.currentTime.text(this.formatTime(currentTime));
    }
  }

  videoTimeForwards(): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }

    let currentTime = this.video.currentTime;
    let duration = this.video.duration;

    if (duration.isNaN) {
      //console.log(`Video not loaded properly. Duration is NaN.`);
    } else if (currentTime === duration) {
      //console.log(`Video already reached its duration.`);
    } else {
      if (this.player.isReduced) {
        if (
          currentTime + this.timeChangeModifier >=
          this.player.reducedVideoStartTime + this.player.reducedVideoDuration
        ) {
          this.video.currentTime = this.player.reducedVideoStartTime + this.player.reducedVideoDuration;
          this.timeUpdate();
        } else {
          this.video.currentTime = currentTime + this.timeChangeModifier;
          this.timeUpdate(this.video.currentTime);
        }
      } else {
        if (currentTime + this.timeChangeModifier >= duration) {
          this.video.currentTime = duration;
          //console.log(`New video currentTime: ${currentTime}`);

          this.timeUpdate();
        } else {
          this.video.currentTime = currentTime + this.timeChangeModifier;
          //console.log(`New video currentTime: ${currentTime}`);

          this.timeUpdate(this.video.currentTime);
        }
      }
    }
  }

  videoTimeBackwards(): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }
    // current video time response checker
    //console.log(`Actual video time: ${this.video.currentTime}s`);

    // total video duration response checker
    //console.log(`Total duration time: ${this.video.duration}s`);

    let currentTime = this.video.currentTime;
    let duration = this.video.duration;

    if (duration.isNaN) {
      //console.log(`Video not loaded properly. Duration is NaN.`);
    } else if (currentTime === 0) {
      //console.log(`You are already at the beginning of this video.`)
    } else {
      if (this.player.isReduced) {
        if (currentTime - this.timeChangeModifier <= this.player.reducedVideoStartTime) {
          this.video.currentTime = this.player.reducedVideoStartTime;
          this.timeUpdate();
        } else {
          this.video.currentTime = currentTime - this.timeChangeModifier;
          this.timeUpdate(this.video.currentTime);
        }
      } else {
        if (currentTime - this.timeChangeModifier <= 0) {
          this.video.currentTime = 0;

          //console.log(`New video currentTime: ${currentTime}`);

          this.timeUpdate();
        } else {
          this.video.currentTime = currentTime - this.timeChangeModifier;
          this.timeUpdate(this.video.currentTime);
        }
      }
    }
  }

  updateTimeBar(e): void {
    let left = Math.floor(e.clientX - this.player.container.get().getBoundingClientRect().left);

    if (left < 0) {
      left = 1;
    }

    if (left > this.progressWrapper.get().offsetWidth) {
      left = this.progressWrapper.get().offsetWidth;
    }

    this.progress.style("width", `${left}px`);

    if (!this.timeDrag) {
      const pos = left / this.progressWrapper.get().offsetWidth;

      if (this.player.isReduced) {
        this.player.playerHandler.seekTo(pos * this.player.reducedVideoDuration + this.player.reducedVideoStartTime);
      } else {
        this.player.playerHandler.seekTo(pos * this.video.duration);
      }
    }
  }

  updateTimeBarOnResize(): void {
    if (!this.video.duration) {
      return;
    }

    let pos: number;

    if (this.player.isReduced) {
      pos = (this.video.currentTime - this.player.reducedVideoStartTime) / this.player.reducedVideoDuration;
      this.player.playerHandler.seekTo(this.player.reducedVideoStartTime + pos * this.player.reducedVideoDuration);
    } else {
      pos = this.video.currentTime / this.video.duration;
      this.player.playerHandler.seekTo(pos * this.video.duration);
    }
  }

  buffered(): void {
    const isBuffered: boolean = this.video.buffered.length > 0;

    // Buffer check
    if (!this.video.buffered || !isBuffered || !this.video.buffered.length) {
      return;
    }

    const duration: number = this.video.duration;
    // const duration = this.player.isReduced ? this.player.reducedVideoDuration : this.video.duration;

    if (duration > 0) {
      for (let i: number = 0; i < this.video.buffered.length; i++) {
        const bufferedTime: number = this.video.buffered.start(i);

        if (bufferedTime < this.video.currentTime && bufferedTime < duration) {
          if (this.player.isReduced) {
            let reducedBufferedTime: number = this.video.buffered.end(i);

            reducedBufferedTime =
              reducedBufferedTime - this.player.reducedVideoStartTime > 0
                ? reducedBufferedTime - this.player.reducedVideoStartTime
                : 0;

            let bufferedPercent =
              ((reducedBufferedTime - this.player.reducedVideoStartTime) /
                (this.player.reducedVideoStartTime + this.player.reducedVideoDuration)) *
              100;

            bufferedPercent = bufferedPercent > 100 ? 100 : bufferedPercent;
            this.buffer.style("width", bufferedPercent + "%");
          } else {
            this.buffer.style(
              "width",
              (this.video.buffered.end(i) / duration) * 100 + "%"
            );
          }

          break;
        }
      }
    }
  }

  handleMouseOver(e): void {
    if (this.player.playerState.isAdvertise.getValue()) {
      return;
    }
    const left = Math.floor(e.clientX - this.player.container.get().getBoundingClientRect().left);
    this.mouseBar.style("width", `${left}px`);
    this.showThumbnail(left);
  }

  handleMouseOut(): any {
    clearTimeout(this.debounce);
    this.mouseBar.style("width", "0px");
    this.thumbnailWrapper.hide();
  }

  showThumbnail(position): void {
    if (!this.player.settings.hideThumbs.active || this.player.touchModeEnabled || !this.baseThumb) {
      return;
    }

    // Disable thumbnails while brand preroll is shown
    if (this.player.options.brandPreroll.enabled && this.player.options.brandPreroll.url === this.player.video.src) {
      return;
    }

    let newThumb: number;
    const time: number = this.player.isReduced ? Math.floor((this.player.reducedVideoDuration / this.progressWrapper.get().offsetWidth) * position) : Math.floor((this.video.duration / this.progressWrapper.get().offsetWidth) * position);
    const vttThumbnailData: any = this.isVTTEnabled ? this.vttThumbnails.getThumbnailData(time) : null;

    if (this.player.isReduced) {
      if (this.isVTTEnabled) {
        newThumb = vttThumbnailData ? vttThumbnailData['id'] : 1;
      } else {
        newThumb = Math.floor(
          (((position / this.progressWrapper.get().offsetWidth) * this.player.reducedVideoDuration +
              this.player.reducedVideoStartTime) /
            this.video.duration) *
          this.thumbsCount
        );
      }
    } else {
      if (this.isVTTEnabled) {
        newThumb = vttThumbnailData ? vttThumbnailData['id'] : 1;
      } else {
        newThumb = Math.floor(position / (this.progressWrapper.get().offsetWidth / this.thumbsCount));
      }
    }

    if (newThumb !== this.currentThumb) {
      if (newThumb === 0) newThumb = 1;

      if (!this.thumbnailElement) {
        this.thumbnailElement = document.querySelector('.video-thumbnail-preview')
      }

      if (this.isVTTEnabled) {
        const thumbnailData = this.vttThumbnails['vttThumbnails'].filter(thumbnail => thumbnail['id'] === newThumb)[0];
        this.thumbnailElement.style.background = `url(${thumbnailData['thumbnailURL']}) -${thumbnailData['position']['x']}px -${thumbnailData['position']['y']}px / ${this.vttThumbnails.vttSpriteImages[thumbnailData['thumbnailURL']]['width']}px ${this.vttThumbnails.vttSpriteImages[thumbnailData['thumbnailURL']]['height']}px`;
      } else {
        if (newThumb > this.thumbsCount) newThumb = 1;
        this.thumbnail.attr("src", this.baseThumb.replace("{THUMB_ID}", newThumb.toString()));
      }

      this.currentThumb = newThumb;
    }

    this.mouseTime.text(this.formatTime(time));
    this.thumbnailWrapper.show();
    this.setThumbnailPosition(position);
  }

  setThumbnailPosition(position): void {
    const thumbWidth: number = this.thumbnailWrapper.get().offsetWidth / 2;
    let left: number = position - thumbWidth;

    if (left < 0) {
      left = 0;
    }

    if (left + this.thumbnailWrapper.get().offsetWidth > this.wrapper.get().offsetWidth) {
      left = this.wrapper.get().offsetWidth - this.thumbnailWrapper.get().offsetWidth;
    }

    this.thumbnailWrapper.style("left", `${left}px`);
  }

  formatTime(seconds): any {
    seconds = seconds < 0 ? 0 : seconds;
    let s = Math.floor(seconds % 60) as any;
    let m = Math.floor((seconds / 60) % 60) as any;
    let h = Math.floor(seconds / 3600) as any;

    // handle invalid times
    if (isNaN(seconds) || seconds === Infinity) {
      // '-' is false for all relational operators (e.g. <, >=) so this setting
      // will add the minimum number of fields specified by the guide
      h = m = s = "-";
    }

    // If hours are showing, we may need to add a leading zero.
    // Always show at least one digit of minutes.
    m = (h >= 1 && m < 10 ? "0" + m : m) + ":";

    // Check if we need to show hours
    h = h > 0 ? h + ":" : "";

    // Check if leading zero is need for seconds
    s = s < 10 ? "0" + s : s;

    return h + m + s;
  }
}
