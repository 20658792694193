import DOMHelp from "./utils/html";
import { IconReplay, IconClose, IconPlay } from "./utils/icons";
import PLAYER_STATE from "./PlayerHandler/PlayerState";
import { VideoEndOverlayImageSize } from "./_config";

export default class VideoEndOverlay {
  public videoData: object[] = [];
  private contentLoaded: boolean = false;
  private activeTab: number = 0;
  public isRendered: boolean = false;
  private closeButton: any;
  private isRequestSent: boolean;
  public overlayEndWrapper: any;
  public overlayEndInner: any;
  public overlayEndHeader: any;
  public overlayEndBody: any;
  public endHeaderButtons: any;
  public endBodyWrappers: any;
  public refreshButton: any;
  public hideButton: any;
  private image: VideoEndOverlayImageSize;
  private amount: number = 0;
  private columns: number = 0;
  private rows: number = 0;
  private lastIndex: number;
  private isCurrentTime: boolean = false;
  public isOverlayWasHidden: boolean = false;

  constructor(private player: any) {
    this.activeTab = this.player.options.videoEndOverlay.activeTab;
    this.isRequestSent = false;
    this.player.video.addEventListener("playing", () => this.hide());
    this.player.video.addEventListener("ended", () => this.show());
    this.player.video.addEventListener("timeupdate", () => this.contentPreloadListener());
    this.image = this.player.options.videoEndOverlay.imageSize;
    this.lastIndex = 0;
    this.updateLink = this.debounce(this.updateLink, 0, false);
  }

  render(): void {
    // if (this.player.startTimeFromSearchParams !== 0 && (this.player.startTimeFromSearchParams === this.player.video.currentTime)) {
    //   return;
    // }

    const playerWrapper = document.querySelector(".video-player");
    const height = playerWrapper.getBoundingClientRect().height - 44;

    // element creation
    this.overlayEndWrapper = new DOMHelp("div")
      .create("div")
      .addClass("video-end-overlay")
      .hide();

    this.overlayEndInner = new DOMHelp("div").addClass("video-end-inner-wrapper");
    this.overlayEndHeader = new DOMHelp("div").addClass("video-end-header");
    this.overlayEndBody = new DOMHelp("div").addClass("video-end-body");

    // adding render of whole overlay to player
    if (this.player.options.videoEndOverlay.enabled) {
      this.player.container.add(this.overlayEndWrapper);
    }

    // JSON Parse INITend-video-link-wrapper
    this.endOverlayJSONRender();
  }

  setData(data): void {
    data.forEach(videoSet => {
      if (videoSet["name"] === "Related Videos") {
        videoSet["videos"].reverse();
      }
    });

    this.videoData = data;

    if (!this.isRendered) {
      this.render();

      // Context menu update while brandPreroll is shown
      if (!this.player.vastHandler.isShown) {
        this.isRendered = true;
      }
    }

    if (this.player.video.ended) {
      this.show();
    }
  }

  setTab(activeTab): void {
    this.endHeaderButtons[this.activeTab].removeClass("active");
    this.endHeaderButtons[activeTab].addClass("active");

    this.endBodyWrappers[this.activeTab].hide();
    this.endBodyWrappers[activeTab].show();

    this.activeTab = activeTab;
  }

  closeAndPlay(): void {
    if (this.player.isReduced) {
      this.player.video.currentTime = this.player.reducedVideoStartTime;
    }

    this.isRendered = false;
    this.player.playerState.state.next(PLAYER_STATE.PLAYING);
    this.hide();

    this.player.play();
  }

  loadContent(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.player.options.videoEndOverlay.url);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };

      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      this.isRequestSent = true;

      xhr.send();
    });
  }

  show(): void {
    // Prevent while change quality
    // if (this.player.qualitySelector.isChangingQuality) {
    //   this.player.qualitySelector.isChangingQuality = false;
    //   return;
    // }

    if (this.player.isReduced && this.player.video.currentTime === this.player.reducedVideoStartTime) {
      return;
    }

    // if (this.player.isVASTComplete) {
    //   console.log('stop 2');
    //   this.player.isVASTComplete = false;
    //   return;
    // }

    if (this.player.video.currentTime === 0) {
      return;
    }

    if (
      this.player.isBrandPrerollShown &&
      (this.player.video.currentTime === 0 ||
        this.player.video.currentTime === this.player.getStartTime() ||
        (!this.player.isSourceVideoShown && this.player.video.currentTime === this.player.video.duration))
    ) {
      setTimeout(() => {
        this.player.isBrandPrerollShown = false;
      }, 1000);
      return;
    }

    if (!this.contentLoaded && !this.isRequestSent) {
      this.loadContent()
        .then(response => {
          return JSON.parse(response);
        })
        .then(json => {
          this.setData(json);
          this.stopLoadingContent();
        })
        .catch(e => {
          this.player.options.videoEndOverlay.enabled = false;
          this.player.log("VideoEndOverlay was switched off. Ads mode was turned on.");

          if (this.player.playerState.isAdvertise.getValue()) {
            return;
          }

          if (
            this.player.ads.enabled &&
            this.player.firstStart === false &&
            this.player.playerState.state.getValue() !== PLAYER_STATE.ERROR
          ) {
            if (this.player.video.ended && this.player.options.videoEndOverlay.enabled) {
              return;
            }

            this.player.ads.render();
          }
        });
    } else {
      if (this.overlayEndWrapper) {
        this.overlayEndWrapper.show();
        this.isRendered = true;

        if (!this.player.isMobile) {
          this.player.titleWrapper.hide();
        }
      }
    }

    this.updateVideoData();
    this.refreshRelated();
    this.amount = this.getVideosAmount();
  }

  hide(): void {
    if (typeof this.overlayEndWrapper !== "undefined") {
      this.overlayEndWrapper.hide();

      if (!this.isOverlayWasHidden) {
        this.player.titleWrapper.show();
      }
    }
  }

  hideOverlay() {
    this.player.options.videoEndOverlay.enabled = false;
    this.player.ads.enabled = false;
    this.player.vastHandler.renderPlayButtonOverlay();
    this.player.vastHandler.setupPlayPauseEventsHandler();

    this.isOverlayWasHidden = true;
    this.hide();
    this.player.titleWrapper.hide();
    this.player.copyLinkWrapper.hide();
    this.endHeaderButtons[this.activeTab].removeClass("active");
    this.activeTab = 0;
    this.setTab(this.activeTab);
  }

  endOverlayJSONRender(): void {
    // return;
    this.endHeaderButtons = [];
    this.endBodyWrappers = [];

    this.videoData.map((item: any) => {
      this.endHeaderButtons.push(this.renderEndHeaderItem(item.name));
      this.endBodyWrappers.push(this.renderEndBodyItem(item.videos));
    });

    this.endHeaderButtons.push(this.renderEndHeaderItem("shareTabName"));
    this.endHeaderButtons.push(this.renderHideHeaderItem("hide"));

    // Uncomment to show Replay Tab
    // this.endHeaderButtons.push(this.renderEndHeaderItem(this.player.lang.get("replayTabName")));

    this.endHeaderButtons.push(this.renderEndHeaderCloseItem());

    this.endBodyWrappers.push(this.renderShareTab());

    this.endBodyWrappers.push(this.renderReplayTab());

    this.overlayEndHeader.addArray(this.endHeaderButtons);
    this.overlayEndBody.addArray(this.endBodyWrappers);

    this.overlayEndInner.addArray([this.overlayEndHeader, this.overlayEndBody]);
    this.overlayEndWrapper.add(this.overlayEndInner);

    this.setTab(this.activeTab);

    // Tab Button Events
    this.endHeaderButtons.forEach((item, i) =>
      item.on("click", e => {
        if (i < this.endHeaderButtons.length - 1) {
          this.setTab(i);
        }
      })
    );

    this.refreshButton = document.querySelector(".refresh-related-button");
    this.refreshButton.addEventListener("click", this.refreshRelated);

    window.addEventListener("resize", () => {
      const videosAmount = this.getVideosAmount();

      if (this.amount !== videosAmount) {
        this.updateVideoData();
        this.refreshRelated();
        this.amount = videosAmount;
      }
    });

    this.hideButton = document.querySelector(".hide-btn");
    this.hideButton.addEventListener("click", () => this.hideOverlay());

    // Close Button Event
    this.closeButton = document.querySelector(".end-btn-close");

    this.closeButton.addEventListener("click", () => {
      this.close();
    });

    // Uncomment to hide close button on video end event

    // this.player.video.addEventListener("ended", () => {
    //   this.closeButton.style.display = "none";
    // });
  }

  close(): void {
    this.hide();

    let isVideoEnded: boolean;

    if (this.player.isReduced) {
      isVideoEnded =
        this.player.reducedVideoStartTime + this.player.reducedVideoDuration <= this.player.video.currentTime;
    } else {
      isVideoEnded = this.player.video.duration - this.player.video.currentTime === 0;
    }

    if (!isVideoEnded) {
      this.player.play();
      this.isRendered = false;
    } else {
      this.closeAndPlay();
    }
  }

  updateVideoData = (): void => {
    const videosAmount = this.getVideosAmount();
    const wrapperList: NodeListOf<HTMLDivElement> = document.querySelectorAll<HTMLDivElement>(".related-list-wrapper");

    const columns: number = this.columns > 4 ? 4 : this.columns;
    const rows: number = this.rows > 4 ? 4 : this.rows;

    for (let i = 0, max = wrapperList.length; i < max; i++) {
      wrapperList[i].style.width = columns * this.image["width"] + "px";
      wrapperList[i].style.height = rows * this.image["height"] + "px";
      (<HTMLDivElement>wrapperList[i].parentNode).classList.add("end-body-thumbs");

      const parent: HTMLDivElement = <HTMLDivElement>wrapperList[i].parentNode;
      parent.style.width = columns * this.image["width"] + "px";
    }

    if (!videosAmount) {
      return;
    }
  };

  refreshRelated = (): void => {
    if (this.videoData[0] && this.videoData[0]["videos"]) {
      const data: any[] = this.videoData[0]["videos"];
      const videosAmount = this.getVideosAmount();
      const videos: any[] = this.getRelatedVideos(data);
      const relatedBlock = document.querySelector(".end-body");

      if (relatedBlock) {
        relatedBlock.innerHTML = this.renderEndBodyItem(videos).getHtml();
      }

      this.updateVideoData();
    }
  };

  getVideosAmount(): number {
    const wrapper: HTMLDivElement = document.querySelector(".video-end-inner-wrapper");

    if (!wrapper) {
      return 0;
    }

    const wrapperSize = wrapper.getBoundingClientRect();
    const width: number = wrapperSize.width - 20;
    const height: number = wrapperSize.height - 70;

    this.columns = Math.floor(width / this.image.width);
    this.rows = Math.floor(height / this.image.height);
    this.columns = this.columns > 4 ? 4 : this.columns;
    this.rows = this.rows > 4 ? 4 : this.rows;

    return this.columns * this.rows;
  }

  getRelatedVideos(videoData): any[] {
    let videos: any[] = [];

    for (let i = 0, max = this.getVideosAmount(); i < max; i++) {
      if (this.lastIndex >= videoData.length) {
        this.lastIndex = 0;
      }

      videos.push(videoData[this.lastIndex]);
      this.lastIndex++;
    }

    return videos;
  }

  renderEndHeaderItem(name): any {
    if (name === "Related Videos") {
      return new DOMHelp("button")
        .addClass("end-btn")
        .addArray([
          new DOMHelp("span").text(this.player.lang.get(name)),
          new DOMHelp("span").addClass("refresh-related-button").html(IconReplay.getHtml())
        ]);
    }

    return new DOMHelp("button").addClass("end-btn").text(this.player.lang.get(name));
  }

  renderHideHeaderItem(name): any {
    return new DOMHelp("button")
      .addClass("end-btn")
      .addClass("hide-btn")
      .text(this.player.lang.get(name));
  }

  renderEndHeaderCloseItem(): any {
    // const closeButtonText = new DOMHelp("span").addClass("end-btn-close-text").text("Resume");
    // const closeButtonIcon = new DOMHelp("span").addClass("end-btn-close-icon").html(IconClose.getHtml());
    //
    // return new DOMHelp("div")
    //     .addClass("end-btn-close")
    //     .addArray([closeButtonText, closeButtonIcon]);

    return new DOMHelp("span").addClass("end-btn-close").html(IconClose.getHtml());
  }

  renderEndBodyItem(videos): any {
    const videosWrappers = videos.map(video => {
      if (video && video["thumbSrc"]) {
        return this.renderVideoItem(video);
      }
    });

    return new DOMHelp("div")
      .addClass("end-body")
      .hide()
      .addList(videosWrappers, "related-list-wrapper");
  }

  renderVideoItem(video) {
    return new DOMHelp("div")
      .addClass("end-video-wrapper")
      .style("backgroundImage", `url("${video.thumbSrc || ""}")`)
      .addArray([
        new DOMHelp("p").addClass("end-thumb-name").text(video.videoName || ""),
        new DOMHelp("div").addClass("end-video-link-wrapper").add(
          new DOMHelp("a")
            .attr("href", video.videoLink || "")
            .attr("target", "_parent")
            .add(new DOMHelp("div").addClass("end-video-link-icon").html(IconPlay.getHtml()))
        )
      ]);
  }

  renderReplayTab(): any {
    const replayButtonWrapper = new DOMHelp("div").addClass("end-replay-button-wrapper");
    const replayText = new DOMHelp("p").text(this.player.lang.get("replayTabText"));
    const replayButton = new DOMHelp("button").addClass("replay-button");

    replayButtonWrapper.addArray([replayText, replayButton]);
    replayButton.html(IconReplay.getHtml());

    replayButton.on("click", () => this.closeAndPlay());

    return new DOMHelp("div")
      .addClass("end-body")
      .hide()
      .add(replayButtonWrapper);
  }

  renderShareTab(): any {
    const shareButtonsWrapper = new DOMHelp("div").addClass("end-share-wrapper");
    // const shareText = new DOMHelp("p").text(this.player.lang.get("shareTabText"));
    const shareWrapper = new DOMHelp("div").addClass(this.player.options.videoEndOverlay.shareClass);

    const textArea = new DOMHelp("textarea")
      .addClass("video-end-copy-link-area")
      .text(this.player.options.copyUrl || location.href)
      .on("click", e => (<any>e).target.select());

    // ---------------

    const copyLinkCheckboxWrapper = new DOMHelp("div").addClass("video-end-copy-checkbox-container").addArray([
      new DOMHelp("label")
        .text(this.player.lang.get("fromCurrentTime"))
        .addClass("video-end-copy-checkbox-wrapper")
        .addArray([
          new DOMHelp("input").attr("type", "checkbox"),
          new DOMHelp("span").addClass("video-end-copy-checkbox-mark")
        ])
        .on("click", e => this.updateLink())
    ]);

    const copyLinkButton = new DOMHelp("div")
      .addClass("video-end-copy")
      .text(this.player.lang.get("copyLink"))
      .on("click", e => this.copyLink());

    const copyLinkContainer = new DOMHelp("div")
      .addClass("video-end-copy-container")
      .addArray([copyLinkCheckboxWrapper, copyLinkButton]);

    // shareButtonsWrapper.addArray([shareText, shareWrapper, textArea, copyLinkContainer]);
    shareButtonsWrapper.addArray([shareWrapper, textArea, copyLinkContainer]);

    return new DOMHelp("div")
      .addClass("end-body")
      .hide()
      .add(shareButtonsWrapper);
  }

  contentPreloadListener(): void {
    if (!this.contentLoaded && !this.player.playerState.isAdvertise.getValue()) {
      if (this.player.video.duration - this.player.video.currentTime <= 20) {
        this.loadContent()
          .then(response => {
            return JSON.parse(response);
          })
          .then(json => {
            this.setData(json);
          })
          .then(() => {
            this.stopLoadingContent();
          })
          .catch(() => {
            this.player.options.videoEndOverlay.enabled = false;
            this.player.log("VideoEndOverlay was switched off. Ads mode was turned on.");
            this.stopLoadingContent();
          });
      }
    }
  }

  stopLoadingContent() {
    this.contentLoaded = true;
    this.player.video.removeEventListener("timeupdate", () => this.contentPreloadListener());
  }

  updateLink(): void {
    this.isCurrentTime = !this.isCurrentTime;

    const textarea: HTMLTextAreaElement = document.querySelector(".video-end-copy-link-area");

    if (this.isCurrentTime) {
      let query = `t=${parseInt(this.player.video.currentTime)}`;

      if (typeof URLSearchParams === "function") {
        let searchParams = new URLSearchParams(location.search);
        searchParams.set("t", parseInt(this.player.video.currentTime).toString());
        query = searchParams.toString();
      }

      textarea.value = `${this.player.options.copyUrl || location.origin + location.pathname}?${query}`;
      return;
    }

    textarea.value = this.player.options.copyUrl || location.href;
  }

  debounce(fn, ms, immediate) {
    let timeout;
    return function() {
      const context = this,
        args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) fn.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, ms);
      if (callNow) fn.apply(context, args);
    };
  }

  copyLink(): void {
    const textarea: HTMLTextAreaElement = document.querySelector(".video-end-copy-link-area");

    textarea.select();
    document.execCommand("copy");
  }
}
