import DOMHelp from "./html";

import fullscreenOff from "../../icons/011-fullscreen-off.svg";
import fullscreenOn from "../../icons/012-fullscreen-on.svg";
import speakerFull from "../../icons/014-sound-on.svg";
import speakerMute from "../../icons/010-sound-off.svg";
import cogWheel from "../../icons/009-cogwheel.svg";
import pause from "../../icons/013-bars.svg";
import play from "../../icons/008-arrows.svg";
import widescreenOff from "../../icons/015-widescreen-off.svg";
import widescreenOn from "../../icons/016-widescreen-on.svg";
import replay from "../../icons/017-replay.svg";
import close from "../../icons/018-cancel.svg";
import back from "../../icons/019-back.svg";
import next from "../../icons/020-next.svg";
import volumeDown from "../../icons/021-volume-down.svg";
import volumeUp from "../../icons/022-volume-up.svg";
import outLink from "../../icons/023-out-link.svg";
import basket from "../../icons/024-basket.svg";
import closeVideoAd from "../../icons/025-close-video-ad.svg";

const getIcon = icon => new DOMHelp().create("span").html(icon);

// export icons
export const IconFullScreenOff = getIcon(fullscreenOff);
export const IconFullScreenOn = getIcon(fullscreenOn);
export const IconSpeakerFull = getIcon(speakerFull);
export const IconSpeakerMute = getIcon(speakerMute);
export const IconCogWheel = getIcon(cogWheel);
export const IconPause = getIcon(pause);
export const IconPlay = getIcon(play);
export const IconReplay = getIcon(replay);
export const IconClose = getIcon(close);
export const IconWidescreenOff = getIcon(widescreenOff);
export const IconWidescreenOn = getIcon(widescreenOn);
export const IconOutLink = getIcon(outLink);
export const IconBasket = getIcon(basket);
export const IconCloseVideoAd = getIcon(closeVideoAd);
export const IconBack = getIcon(back);
export const IconNext = getIcon(next);
export const IconVolumeDown = getIcon(volumeDown);
export const IconVolumeUp = getIcon(volumeUp);

// export default all
const Icons = {
  fullScreenOff: IconFullScreenOff,
  fullScreenOn: IconFullScreenOn,
  speakerFull: IconSpeakerFull,
  speakerMute: IconSpeakerMute,
  cogWheel: IconCogWheel,
  pause: IconPause,
  play: IconPlay,
  replay: IconReplay,
  close: IconClose,
  widescreenOff: IconWidescreenOff,
  widescreenOn: IconWidescreenOn,
  back: IconBack,
  next: IconNext,
  volumeDown: IconVolumeDown,
  volumeUp: IconVolumeUp,
  outLink: IconOutLink,
  basket: IconBasket,
  closeVideoAd: IconCloseVideoAd
};

export default Icons;
