/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item): boolean {
  return item && typeof item === "object" && !Array.isArray(item) && true;
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export function mergeDeep(target, source): any {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) (<any>Object).assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        (<any>Object).assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
}

export function cloneDeep(object): any {
  return JSON.parse(JSON.stringify(object));
}
