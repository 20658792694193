import DOMHelp from "./utils/html";
import { IconWidescreenOff, IconWidescreenOn } from "./utils/icons";
import SettingsToggleItem from "./settings/_settingsToggleItem";

export default class Widescreen extends SettingsToggleItem {
  constructor(public player: any) {
    super(player);

    this.player = player;
    this.localStorageKey = "html5PlayerWideScreen";
    this.config = this.player.options.widescreen;

    if (this.config.enabled) {
      this.active = this.getDefaultState();
      this.render();

      // Activate widescreen feature if user is logged
      if (this.isLogged()) {
        this.setupEvents();
      }
    }
  }

  render(): void {
    this.buttonWrapper = new DOMHelp()
      .create("div")
      .addClass("widescreen-button-wrapper")
      .addClass("tooltip-button");

    this.button = new DOMHelp().create("button").addClass("video-widescreen");
    this.setState();
    this.buttonWrapper.add(this.button);
    this.config.handleAction(this.active);
  }

  setupEvents(): void {
    this.buttonWrapper.on("click", () => {
      this.handleToggle();
      this.config.handleAction(this.active);
    });

    this.player.playerState.isCasting.subscribe(isCasting => {
      if (isCasting) {
        this.buttonWrapper.hide();
      } else {
        this.buttonWrapper.show();
      }
    });

    this.player.playerState.isFullscreen.subscribe(isFullscreen => {
      if (isFullscreen) {
        this.buttonWrapper.hide();
      } else {
        this.buttonWrapper.show();
      }
    });
  }

  setState(): void {
    this.button.html(this.active ? IconWidescreenOff.getHtml() : IconWidescreenOn.getHtml());
    this.buttonWrapper.attr("data-tooltip", this.player.lang.get(this.active ? "widescreenOff" : "widescreenOn"));
    this.player.playerState.isWidescreen.next(this.active);
  }

  isLogged(): boolean {
    // Check if user is logged
    return document.cookie.indexOf("uLogged") !== -1;
  }
}
