import DOMHelp from "./utils/html";
import { IconFullScreenOff, IconFullScreenOn } from "./utils/icons";

export default class Fullscreen {
  private fullScreenEnabled: any;
  private fullscreenWrapperBtn: any;
  private fullscreen: any;

  constructor(private player: any) {
    this.fullScreenEnabled = !!(
      (<any>document).fullscreenEnabled ||
      (<any>document).mozFullScreenEnabled ||
      (<any>document).msFullscreenEnabled ||
      (<any>document).webkitSupportsFullscreen ||
      (<any>document).webkitFullscreenEnabled ||
      (<any>document).createElement("video").webkitRequestFullScreen ||
      this.player.video.webkitSupportsFullscreen
    );
    this.render();
    this.setupEvents();
  }

  render(): void {
    this.fullscreenWrapperBtn = new DOMHelp()
      .create("div")
      .addClass("fullscreen-button-wrapper")
      .addClass("tooltip-button")
      .addClass("tooltip-far-right")
      .attr("data-tooltip", this.player.lang.get("fullScreen"));

    this.fullscreen = new DOMHelp()
      .create("button")
      .addClass("video-fullscreen")
      .html(IconFullScreenOn.getHtml());

    this.fullscreenWrapperBtn.add(this.fullscreen);

    if (!this.fullScreenEnabled) {
      this.fullscreenWrapperBtn.hide();
    }
  }

  getBtn(): any {
    return this.fullscreenWrapperBtn;
  }

  setupEvents(): void {
    this.fullscreenWrapperBtn.on("click", () => this.handleFullscreen());

    document.addEventListener("fullscreenchange", () => {
      this.setFullscreenData(!!((<any>document).fullScreen || document.fullscreenElement));
    });

    document.addEventListener("webkitfullscreenchange", () => {
      this.setFullscreenData(!!(<any>document).webkitIsFullScreen);
    });

    document.addEventListener("mozfullscreenchange", () => {
      this.setFullscreenData(!!(<any>document).mozFullScreen);
    });

    document.addEventListener("msfullscreenchange", () => {
      this.setFullscreenData(!!(<any>document).msFullscreenElement);
    });

    this.player.video.addEventListener("loadedmetadata", e => {
      if (document.fullscreenEnabled || this.player.video.webkitSupportsFullscreen) {
        this.fullScreenEnabled = true;
        this.fullscreenWrapperBtn.show();
      }
    });

    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream) {
      window.addEventListener("orientationchange", () => this.handleScreenOrientationChange());
    }

    if (typeof (<any>screen).orientation !== "undefined") {
      (<any>screen).orientation.addEventListener("change", () => this.handleScreenOrientationChange());
    }

    this.player.playerState.isCasting.subscribe(isCasting => {
      if (isCasting && this.fullScreenEnabled) {
        this.fullscreenWrapperBtn.hide();
      } else {
        this.fullscreenWrapperBtn.show();
      }
    });
  }

  handleScreenOrientationChange(): void {
    if (window.orientation === -90 || window.orientation === 90) {
      this.turnOnFullScreen();
    } else {
      this.turnOffFullScreen();
    }
  }

  handleFullscreen(): void {
    this.isFullScreen() ? this.turnOffFullScreen() : this.turnOnFullScreen();
  }

  turnOffFullScreen(): void {
    // TODO: update fullscreen function
    // if (document.exitFullscreen && (document.fullscreenElement || document.webkitFullscreenElement)) document.exitFullscreen();

    if ((<any>window).fullScreen && document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((<any>document).webkitExitFullscreen) {
      (<any>document).webkitExitFullscreen();
    } else if ((<any>document).msExitFullscreen) {
      (<any>document).msExitFullscreen();
    } else if ((<any>document).mozCancelFullScreen) {
      (<any>document).mozCancelFullScreen();
    }

    this.setFullscreenData(false);
  }

  turnOnFullScreen(): void {
    const wrapper = this.player.containerWrapper.get();

    try {
      if (wrapper.requestFullscreen) {
        wrapper.requestFullscreen();
      } else if (wrapper.mozRequestFullScreen) {
        wrapper.mozRequestFullScreen();
      } else if (wrapper.webkitRequestFullscreen) {
        wrapper.webkitRequestFullscreen();
      } else if (wrapper.msRequestFullscreen) {
        wrapper.msRequestFullscreen();
      } else if (this.player.video.webkitSupportsFullscreen) {
        this.player.video.webkitEnterFullscreen();
        return;
      } else {
        return;
      }

      this.setFullscreenData(true);
    } catch (errorMessage) {
      this.player.log(errorMessage);
    }
  }

  isFullScreen(): any {
    return !!(
      (<any>document).fullScreen ||
      (<any>document).webkitIsFullScreen ||
      (<any>document).mozFullScreen ||
      (<any>document).msFullscreenElement ||
      (<any>document).fullscreenElement
    );
  }

  setFullscreenData(state): void {
    // VAST tracker fullscreen event handling
    if (this.player.vastHandler.isActive && this.player.vastHandler.vastTracker) {
      this.player.vastHandler.vastTracker.setFullscreen(state);
    }

    if (!!state) {
      this.player.container.addClass("fullscreen");
      this.fullscreen.html(IconFullScreenOff.getHtml());
      this.fullscreenWrapperBtn.attr("data-tooltip", this.player.lang.get("exitFullScreen"));
      this.player.playerState.isFullscreen.next(true);
    } else {
      this.player.container.removeClass("fullscreen");
      this.fullscreen.html(IconFullScreenOn.getHtml());
      this.fullscreenWrapperBtn.attr("data-tooltip", this.player.lang.get("fullScreen"));
      this.player.playerState.isFullscreen.next(false);
    }
  }
}
